import { Spinner } from "components/common/Spinner";
import { NavHeader } from "components/layout/NavHeader";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useGetSimilarVendors } from "./api";
import Header from "./components/Header";
import useUpdateParams from "hooks/useUpdateParams";
import VendorCombineTable from "./components/VendorCombineTable";

export default function VendorCombine() {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const updateParams = useUpdateParams();
  const [similarVendors, setSimilarVendors] = useState([]);
  const similarity_value = searchParams.get("similarity") || 1;
  const [actualVendorName, setActualVendorName] = useState("");
  const [similarity, setSimilarity] = useState(similarity_value);
  const { vendorID } = useParams();
  const [checkedVendors, setCheckedVendors] = useState([]);
  const handleCheckedChange = (value, vendor_id, vendor_name) => {
    if (value == true) {
      setCheckedVendors([
        ...checkedVendors,
        {
          vendor_id,
          vendor_name
        }
      ]);
    } else {
      let fil = checkedVendors?.filter((item) => item?.vendor_id !== vendor_id);
      setCheckedVendors(fil);
    }
  };
  const { data, isLoading } = useGetSimilarVendors({
    vendor_id: vendorID,
    similarity: similarity_value
  });
  return (
    <>
      <NavHeader view="Combine Vendors" />
      <Header text={"Combine Vendors"} />
      <ToastContainer />
      {isLoading&& (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <VendorCombineTable
            vendorData={data}
            actualVendorName={actualVendorName}
            setActualVendorName={setActualVendorName}
            // handleCombineVendors={handleCombineVendors}
          />
          <div className="my-4 container">
            <label>Similarity : {similarity} </label>
            <input
              class="form-range d-block mx-auto my-2 bg-secondary"
              type="range"
              value={similarity}
              onChange={(e) => setSimilarity(parseInt(e.target.value))}
              min={0}
              max={100}
              step="1"
              style={{ width: "50%" }}
            />
          </div>
          <div className="my-4 container">
            <button
              type="button"
              disabled={isLoading}
              className="btn btn-info d-block mx-auto my-2"
              onClick={() => {
                updateParams({ similarity: similarity });
              }}
              style={{ width: "50%" }}
            >
              Find Similar Vendors
            </button>
          </div>
        </>
      )}
    </>
  );
}
