import React, { useState, useEffect } from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "./components/Header";
import {
  getVendorBranchDetails,
  saveBranchDetails,
  useGetBranchDetails
} from "./api";
import { useParams } from "react-router-dom";
import { Spinner } from "components/common/Spinner";
import BranchDetailsTable from "./components/BranchDetails";
import { toast, ToastContainer } from "react-toastify";

export default function BranchDetails() {
  const { branchId } = useParams();
  const { data, isLoading } = useGetBranchDetails(branchId);

  return (
    <>
      <NavHeader view="Vendor Branch Details" />
      {isLoading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <Header
            text={`Vendor Branch Details for ${data?.data?.vendor_address}`}
          />
          <BranchDetailsTable data={data} />
        </>
      )}
      <ToastContainer />
    </>
  );
}
