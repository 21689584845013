import {
  ArrowRightCircleFill,
  ArrowLeftCircleFill
} from "react-bootstrap-icons";
import useHomePagination from "store/homePagination";
import useUpdateSearchParams from "hooks/useUpdateSearchParams";
export const HomePagination = ({ totalInvoices }) => {
  const {
    tempValue,
    actions: { setPageNumber, setTempValue }
  } = useHomePagination();
  const updateSearchParams = useUpdateSearchParams();
  const handleInputChange = (e) => {
    const newValue = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
    setTempValue(parseInt(newValue));
  };

  const handleBlur = () => {
    setPageNumber(tempValue);
    updateSearchParams({ page_index: tempValue });
  };

  return (
    <div className="my-4">
      <button
        className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
        disabled={tempValue - 1 <= 0}
        onClick={() => {
          setPageNumber(tempValue - 1);
          setTempValue(tempValue - 1);
          updateSearchParams({ page_index: tempValue - 1 });
        }}
      >
        <ArrowLeftCircleFill size={40} />
      </button>
      <span className="my-4 mx-2">
        <input
          value={tempValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          className="btn btn-secondary"
          style={{ width: "7ch" }}
        />
        <span className="my-4">
          {" "}
          <strong>/</strong>{" "}
          {totalInvoices ? (
            <input
              disabled
              value={`${totalInvoices}`}
              className="btn btn-secondary"
              style={{ width: "7ch", cursor: "default" }}
            />
          ) : (
            <input
              disabled
              value={`${""}`}
              className="btn btn-secondary"
              style={{ width: "7ch", cursor: "default" }}
            />
          )}
        </span>
      </span>
      <button
        className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
        disabled={totalInvoices <= tempValue}
        onClick={() => {
          setPageNumber(tempValue + 1);
          setTempValue(tempValue + 1);
          updateSearchParams({ page_index: tempValue + 1 });
        }}
      >
        <ArrowRightCircleFill size={40} />
      </button>
    </div>
  );
};
