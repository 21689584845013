import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatDate } from "utils/format";
import { useParams } from "react-router-dom";
import { getVendorBranchPdfs, renameVendor } from "../api";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { Trash3Fill, PatchCheckFill } from "react-bootstrap-icons";
import CustomDropdown from "components/CustomDropdown";
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  ZoomIn,
  ZoomOut,
  Download,
  PenFill,
  SaveFill,
  XCircleFill
} from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useVendorList } from "components/Tables/EditMetaDataTable";
import useGetNestedFilterValue from "hooks/useGetNestedFilterValue";
const { migrateBranch } = require("../api");
const PdfViewerComponent = ({ vendorId, branchId, setViewInvoice }) => {
  const [pdfs, setPdfs] = useState([]);
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getVendorBranchPdfs(vendorId, branchId)
      .then((res) => {
        setPdfs(res.pdfs_link);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [branchId, vendorId]);

  const handlePrevClick = () => {
    if (currentPdfIndex > 0) {
      setCurrentPdfIndex(currentPdfIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPdfIndex < pdfs.length - 1) {
      setCurrentPdfIndex(currentPdfIndex + 1);
    }
  };

  const currentPdf = pdfs.length > 0 ? pdfs[currentPdfIndex] : null;
  const currentPdfUrl = currentPdf ? currentPdf.link : null;
  const currentPdfSource = currentPdf ? currentPdf.source : null;

  return (
    <div className="container border border-warning rounded p-3 mb-4">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="row justify-content-center mt-3">
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-primary w-100"
                onClick={handlePrevClick}
                disabled={currentPdfIndex === 0}
              >
                Previous
              </button>
            </div>
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-primary w-100"
                onClick={handleNextClick}
                disabled={currentPdfIndex === pdfs.length - 1}
              >
                Next
              </button>
            </div>
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-danger w-100"
                onClick={() => setViewInvoice(false)}
              >
                Close
              </button>
            </div>
          </div>
          <div className="">
            <div className="">
              {currentPdfSource === "google_drive" ? (
                <iframe
                  title="pdf"
                  src={`${currentPdfUrl?.replace(
                    "uc?id=",
                    "file/d/"
                  )}/preview?embedded=true`}
                  width="100%"
                  height="470"
                  allow="autoplay"
                ></iframe>
              ) : currentPdfSource === "azure_blob" ||
                currentPdfSource === "clickbacon" ? (
                <DocumentComponent pdfUrl={currentPdfUrl} />
              ) : (
                <div>No PDFs available</div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const DocumentComponent = ({ pdfUrl }) => {
  const [pdfScale, setPdfScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  function changePage(offset) {
    const newPageNum = pageNum + offset;
    setPageNum(newPageNum);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNum(1);
  }
  return (
    <>
      <div
        className="ml-50 p-2 rounded"
        style={{ zIndex: "50", backgroundColor: "rgb(240, 240, 240)" }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ gap: "16px" }}
        >
          <ZoomIn
            height={20}
            width={20}
            disabled={pdfScale >= 8}
            className="cursor-pointer"
            onClick={() => setPdfScale((s) => s * 2)}
          />
          <ZoomOut
            height={20}
            width={20}
            disabled={pdfScale <= 0.1}
            className="cursor-pointer"
            onClick={() => setPdfScale((s) => s / 2)}
          />
          <button
            type="button"
            disabled={pageNum <= 1}
            onClick={previousPage}
            className="btn btn-sm btn-outline-secondary"
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNum >= numPages}
            onClick={nextPage}
            className="btn btn-sm btn-outline-secondary"
          >
            Next
          </button>
          <ArrowClockwise
            height={20}
            width={20}
            className="cursor-pointer"
            onClick={() => setRotation((r) => (r === 270 ? 0 : r + 90))}
          />
          <ArrowCounterclockwise
            height={20}
            width={20}
            className="cursor-pointer"
            onClick={() => setRotation((r) => (r === 0 ? 270 : r - 90))}
          />
          <div>
            <span>
              Page {pageNum} of {numPages}
            </span>
            <Download
              height={20}
              width={20}
              className="cursor-pointer mx-4"
              onClick={() => window.open(pdfUrl, "_blank")}
            />
          </div>
        </div>
      </div>
      <div style={{ height: "470px", overflow: "auto" }}>
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNum} scale={pdfScale} rotate={rotation} />
        </Document>
      </div>
    </>
  );
};

export default function VendorBranchesTable({
  data,
  masterVendor,
  setMasterVendor,
  vendorsToMerge,
  setVendorsToMerge,
  getVendorBranchesFn,
  handleDeleteVendorBranch
}) {
  const { vendorID } = useParams();
  const [viewInvoice, setViewInvoice] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [edit, setEdit] = useState(null);
  const [updatedName, setUpdatedname] = useState("");
  const [renaming, setRenaming] = useState(false);
  const [vendorBranchIdToDelete, setVendorBranchIdToDelete] = useState(null);
  const [vendorBranchNameToDelete, setVendorBranchNameToDelete] =
    useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMigrateModal, setShowMigrateModal] = useState(false);
  const [migrateToVendor, setMigrateToVendor] = useState(null);
  const [branchToMigrate, setBranchToMigrate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [migrating, setMigrating] = useState(false);
  const getNestedFilterValue = useGetNestedFilterValue();
  const rest_id = getNestedFilterValue(["filters", "restaurant_id"]);
  const {
    data: vendorData,
    isPending,
    error,
    isError
  } = useVendorList(rest_id);

  const handleRenameVendor = (branchId, updatedVendorName) => {
    setRenaming(true);
    const body = {
      vendor_id: vendorID,
      branch_id: branchId,
      updated_vendor_address: updatedVendorName
    };
    renameVendor(body)
      .then((res) => {
        toast.success(res.message);
        setUpdatedname("");
        setEdit(null);
        setRenaming(false);
        getVendorBranchesFn();
      })
      .catch((err) => {
        toast.error("Error renaming vendor");
        setRenaming(false);
      });
  };

  const handleBranchMigrate = () => {
    if (!migrateToVendor) {
      toast.error("Please select a vendor to migrate to");
      return;
    }
    if (!branchToMigrate) {
      toast.error("Please select a branch to migrate");
      return;
    }
    setMigrating(true);
    const payload = {
      current_vendor_id: vendorID,
      branch_id: branchToMigrate,
      new_vendor_id: migrateToVendor
    };
    migrateBranch(payload)
      .then((res) => {
        toast.success(res.message);
        setShowMigrateModal(false);
        setMigrateToVendor(null);
        setBranchToMigrate(null);
        getVendorBranchesFn();
        setMigrating(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message || "Error migrating branch");
        setMigrating(false);
      });
  };
  return (
    <div className="mx-5" style={{ marginTop: "50px", display: "flex" }}>
      <div
        className={`table-responsive  ${
          viewInvoice ? "col-md-6 mx-2" : "col-md-12"
        }`}
      >
        <Table
          striped
          bordered
          hover
          variant="light"
          responsive
          className="shadow-sm"
        >
          <thead>
            <tr>
              <th style={{ width: "40%", verticalAlign: "middle" }}>
                Vendor Address{" "}
              </th>
              <th style={{ width: "20%" }}>Document Count</th>
              <th style={{ width: "20%" }}>Created Date</th>
              <th style={{ width: "10%" }}>Select Master</th>
              <th style={{ width: "10%" }}>Select for Merge</th>
              <th style={{ width: "10%" }}>Migrate</th>
              <th style={{ width: "10%" }}>View Invoice</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  {edit !== item.branch_id ? (
                    <Link
                      to={`/vendor/${vendorID}/branch/${item.branch_id}`}
                      style={{ color: "black", textDecoration: "underline" }}
                    >
                      {item.vendor_address}{" "}
                      {item.human_verified && <PatchCheckFill fill="blue" />}
                    </Link>
                  ) : (
                    <textarea
                      className="form-control"
                      type="text"
                      value={updatedName}
                      onChange={(e) => setUpdatedname(e.target.value)}
                    />
                  )}
                </td>
                <td>{item.document_count}</td>
                <td>{formatDate(item.created_date)}</td>
                <td>
                  <input
                    className="form-check-input border border-dark"
                    type="radio"
                    name="master"
                    value={item.branch_id}
                    checked={masterVendor === item.branch_id}
                    onChange={(e) => setMasterVendor(e.target.value)}
                    disabled={vendorsToMerge.includes(item.branch_id)}
                  />
                </td>
                <td>
                  <input
                    className="form-check-input border border-dark"
                    type="checkbox"
                    value={item.branch_id}
                    checked={vendorsToMerge.includes(item.branch_id)}
                    disabled={
                      item?.human_verified || masterVendor === item.branch_id
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setVendorsToMerge([...vendorsToMerge, e.target.value]);
                      } else {
                        setVendorsToMerge(
                          vendorsToMerge.filter((id) => id !== e.target.value)
                        );
                      }
                    }}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      setShowMigrateModal(true);
                      setBranchToMigrate(item.branch_id);
                    }}
                  >
                    Migrate
                  </button>
                </td>
                <td>
                  {viewInvoice && selectedBranch === item.branch_id ? (
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        setViewInvoice(false);
                        setSelectedBranch(null);
                      }}
                    >
                      Hide
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        setViewInvoice(true);
                        setSelectedBranch(item.branch_id);
                      }}
                    >
                      View
                    </button>
                  )}
                </td>
                <td>
                  {edit === item.branch_id && renaming == false ? (
                    <div className="d-flex">
                      <SaveFill
                        className="mx-2 my-2"
                        style={{ cursor: "pointer", color: "green" }}
                        onClick={() => {
                          if (updatedName === item.vendor_address) {
                            setEdit(null);
                            setUpdatedname("");
                            return;
                          }
                          handleRenameVendor(item.branch_id, updatedName);
                        }}
                      />
                      <XCircleFill
                        className="mx-2 my-2"
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => {
                          setEdit(null);
                          setUpdatedname("");
                        }}
                      />
                    </div>
                  ) : edit == item.branch_id && renaming ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <PenFill
                      className="mx-2 my-2"
                      style={{
                        cursor: "pointer",
                        color: edit ? "grey" : "black"
                      }}
                      onClick={() => {
                        setEdit(item.branch_id);
                        setUpdatedname(item.vendor_address);
                      }}
                    />
                  )}
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      setVendorBranchIdToDelete(item.branch_id);
                      setVendorBranchNameToDelete(item.vendor_address);
                      setShowDeleteModal(true);
                    }}
                  >
                    <Trash3Fill />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {viewInvoice && (
        <PdfViewerComponent
          setViewInvoice={setViewInvoice}
          vendorId={vendorID}
          branchId={selectedBranch}
        />
      )}
      <ToastContainer />
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setVendorBranchIdToDelete(null);
          setVendorBranchNameToDelete(null);
          setShowDeleteModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Vendor Branch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{" "}
          <strong>{vendorBranchNameToDelete}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              setShowDeleteModal(false);
              handleDeleteVendorBranch(vendorBranchIdToDelete);
            }}
          >
            Delete
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setShowDeleteModal(false);
              setVendorBranchIdToDelete(null);
              setVendorBranchNameToDelete(null);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Vendor Migrate Modal */}
      <Modal
        show={showMigrateModal}
        onHide={() => {
          setMigrateToVendor(null);
          setShowMigrateModal(false);
          setBranchToMigrate(null);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Vendor Branch Migrate Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="branch">Branch</label>
          <input
            type="text"
            className="form-control"
            value={
              data.find((branch) => branch.branch_id === branchToMigrate)
                ?.vendor_address
            }
            disabled
          />
          <label htmlFor="vendor">Select Vendor to Migrate to:</label>
          {/* <Dropdown
            onSelect={(v) => {
              if (v === "-1") {
                return;
              }
              setMigrateToVendor(v);
            }}
          >
            <Dropdown.Toggle
              variant="outline"
              className="border w-100 bg-white h-100"
              style={{
                lineHeight: "1.2",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              {vendorData?.find(
                (vendor) => vendor.vendor_id === migrateToVendor
              )?.vendor_name || "All Vendors"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxWidth: "300px",
                minHeight: "100px",
                maxHeight: "250px",
                overflowY: "scroll",
                overflowX: "scroll",
              }}
            >
              {isPending ? (
                <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                  <Spinner />
                </div>
              ) : isError ? (
                error.message
              ) : (
                <>
                  {/* <FormControl
                    type="text"
                    className="form-control mx-auto mb-4"
                    placeholder="Search"
                    onInput={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    style={{
                      width: "90%",
                    }}
                  /> */}
          {/* <Dropdown.Item eventKey={null}>{"All Vendors"}</Dropdown.Item>
                  {vendorData?.map((vendor, i) => (
                    <Dropdown.Item
                      className="d-flex justify-content-between align-items-center"
                      key={vendor.vendor_id}
                      eventKey={vendor.vendor_id}
                    >
                      {vendor.vendor_name}
                      {vendor.human_verified && <PatchCheckFill fill="blue" />}
                    </Dropdown.Item>
                  ))}
                </>
              )}
            </Dropdown.Menu>
          </Dropdown> */}
          <CustomDropdown
            data={vendorData}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            Objectkey={"vendor_id"}
            displayKey={"vendor_name"}
            className="w-auto"
            maxWidth="100%"
            minWidth="100%"
            value={
              vendorData?.find((vendor) => vendor.vendor_id === migrateToVendor)
                ?.vendor_name || "All Vendors"
            }
            // setValue={setValue}
            // Objectkey={'label'}
            showVerificationBadge={true}
            verification_key={"human_verified"}
            onSelect={(v) => {
              setMigrateToVendor(v);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            onClick={handleBranchMigrate}
            disabled={migrating}
          >
            {migrating ? "Migrating..." : "Migrate"}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setShowMigrateModal(false);
              setMigrateToVendor(null);
              setBranchToMigrate(null);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
