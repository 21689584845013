import { queryClient } from "lib/react-query";
import { useState } from "react";
import { Dropdown, Form, FormControl, Modal, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDeleteVendorBranch, useUpdateBranchDetails } from "../api";

const BranchDetailsTable = ({ data = [], handleSaveBranchDetails }) => {
  const [formData, setFormData] = useState(data);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { vendorId, branchId } = useParams();
  function formatDate(input) {
    const date = new Date(input);
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  }
  const setCacheData = (key, val) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    copyObj.data[`${key}`] = val;
    queryClient.setQueryData(["vendor-branch-details", branchId], copyObj);
  };

  const { mutate: saveBranchDetails } = useUpdateBranchDetails();
  const {mutate:deleteBranch}=useDeleteVendorBranch()
  return (
    <div
      className="mx-5"
      style={{
        marginTop: "50px"
      }}
    >
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Vendor Address</td>
            <td>
              <FormControl
                value={data?.data?.vendor_address}
                onChange={(e) => setCacheData("vendor_address", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Created Date</td>
            <td style={{ textAlign: "left" }}>
              <FormControl
                value={formatDate(data?.data?.created_date)}
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>Last Modified Date</td>
            <td style={{ textAlign: "left" }}>
              <FormControl
                value={formatDate(data?.data?.last_modified_date)}
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>Human Verified</td>
            <td>
              <Form.Check
                type="switch"
                checked={data?.data?.human_verified}
                onChange={(e) =>
                  setCacheData("human_verified", e.target.checked)
                }
              />
            </td>
          </tr>
          <tr>
            <td>Document Count</td>
            <td>
              <FormControl value={data?.data?.document_count} disabled />
            </td>
          </tr>
          <tr>
            <td>Venor City</td>
            <td>
              <FormControl
                value={data?.data?.vendor_city}
                onChange={(e) => setCacheData("vendor_city", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Vendor Phone Number</td>
            <td>
              <FormControl
                value={data?.data?.vendor_phone_number}
                onChange={(e) =>
                  setCacheData("vendor_phone_number", e.target.value)
                }
              />
            </td>
          </tr>
          <tr>
            <td>Vendor State</td>
            <td>
              <FormControl
                value={data?.data?.vendor_state}
                onChange={(e) => setCacheData("vendor_state", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Vendor Street</td>
            <td>
              {" "}
              <FormControl
                value={data?.data?.vendor_street}
                onChange={(e) => setCacheData("vendor_street", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Vendor Zip Code</td>
            <td>
              {" "}
              <FormControl
                value={data?.data?.vendor_zip_code}
                onChange={(e) =>
                  setCacheData("vendor_zip_code", e.target.value)
                }
              />
            </td>
          </tr>
          <tr>
            <td>Vendor Address Synonyms</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="success">
                  {data?.data?.["vendor_address_synonyms"]?.[0] ??
                    "Vendor Address Synonyms"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {data?.data?.["vendor_address_synonyms"]?.map((synm) => {
                    return (
                      <Dropdown.Item
                        key={synm}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 10
                        }}
                      >
                        {synm}{" "}
                        <button
                          onClick={() => {
                            setCacheData(
                              "vendor_address_synonyms",
                              data?.data?.["vendor_address_synonyms"]?.filter(
                                (it) => it !== synm
                              )
                            );
                          }}
                          style={{ border: "1px solid gray", borderRadius: 5 }}
                        >
                          Remove
                        </button>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td>Vendor Id</td>
            <td>
              {" "}
              <FormControl value={data?.data?.vendor_id} disabled />
            </td>
          </tr>
          <tr>
            <td>Branch Id</td>
            <td>
              {" "}
              <FormControl value={data?.data?.branch_id} disabled />
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="mt-4 mb-3">
        <div>
          <button
            disabled={saving}
            className="btn btn-success"
            style={{ width: "50%" }}
            onClick={() => {
              setSaving(true);
              saveBranchDetails(
                { branch_id: branchId, payload: data?.data },
                {
                  onSuccess: () => {
                    setSaving(false);
                  },
                  onError: () => {
                    setSaving(false);
                  }
                }
              );
            }}
          >
            {saving ? "Saving...." : "Save"}
          </button>
        </div>
        <div className="mt-3">
          <button
            disabled={saving}
            className="btn btn-danger"
            style={{ width: "50%" }}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </button>
        </div>
      </div>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this branch?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <tbody>
              <tr>
                <td>Vendor Address</td>
                <td>{data?.data?.vendor_address}</td>
              </tr>
              <tr>
                <td>Branch ID</td>
                <td>{branchId}</td>
              </tr>
            </tbody>
          </table>
          {/* <p className="text-muted fs-6">
            This action cannot be undone. All items associated with this branch
            will also be deleted.
          </p> */}
        </Modal.Body>
        <Modal.Footer>
          <button disabled={deleting} className="btn btn-danger" onClick={() => {
            setDeleting(true)
            deleteBranch(branchId,{
              onSuccess:()=>{
                setDeleting(false)
              },
              onError:()=>{
                setDeleting(false)
              }
            })
          }}>
            {deleting?"Deleting...":"Delete"}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BranchDetailsTable;
