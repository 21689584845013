import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "lib/axios";

export const useFetchDuplicateInvoices = (documentUUID) =>{
    return useQuery({
        queryKey: ["duplicateInvoices", documentUUID],
        queryFn: async () => {
            const response = await axiosInstance.get(`/find_duplicate_invoices?document_uuid=${documentUUID}`)
            return response.data
        },
        enabled: !!documentUUID
    })
}