import {
  ArrowRightCircleFill,
  ArrowLeftCircleFill,
} from "react-bootstrap-icons";
import useInvoicePagination from "store/invoicePagination";
import React, { useEffect } from "react";
import useAppStore from "store/global";
import useUpdateSearchParams from "hooks/useUpdateSearchParams";
import useGetNestedFilterValue from "hooks/useGetNestedFilterValue";
import { useSearchParams } from "react-router-dom";
export const InvoicePagination = ({ setHoveredCellCoordinates }) => {
  const updateSearchParams = useUpdateSearchParams();
  const getNestedFilterValue = useGetNestedFilterValue();
  const [searchParams] = useSearchParams();
  const { tempValue, totalInvoices, setPageNumber, setTempValue } =
    useInvoicePagination();
  const {
    setIsTimerRunning,
    loading,
    setLockZoomAndScroll,
    setScale,
    setSessionStartTime,
  } = useAppStore();
  const handleInputChange = (e) => {
    console.log(e.target.value)
    // const newValue = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
    setTempValue(Number(e.target.value));
  };

  const handleBlur = () => {
    
    setPageNumber(tempValue);
    
    setLockZoomAndScroll(false);
    setScale(1);
    updateSearchParams({ pdf_index: tempValue });
    setHoveredCellCoordinates(null);
  };

  const handleKeyPress = (event) => {
    const focusedElementType = document.activeElement.tagName.toLowerCase();

    if (focusedElementType !== "input" && focusedElementType !== "textarea") {
      if (event.key === "ArrowRight") {
        if (tempValue + 1 > totalInvoices) {
          return;
        }
        setPageNumber(tempValue + 1);
        setLockZoomAndScroll(false);
        setScale(1);
        updateSearchParams({ pdf_index: tempValue + 1 });
        setTempValue(tempValue + 1);
        const nt = new Date().getTime();
        setSessionStartTime(nt);
      } else if (event.key === "ArrowLeft") {
        if (tempValue - 1 <= 0) {
          return;
        }
        setPageNumber(tempValue - 1);
        setLockZoomAndScroll(false);
        setScale(1);
        updateSearchParams({ pdf_index: tempValue - 1 });
        setTempValue(tempValue - 1);
        const nt = new Date().getTime();
        setSessionStartTime(nt);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  useEffect(()=>{
setTempValue(getNestedFilterValue(["pdf_index"]))
  },[])

  return (
    <div className="my-4">
      <button
        className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
        disabled={tempValue - 1 <= 0}
        onClick={() => {
          setPageNumber(tempValue - 1);
          setLockZoomAndScroll(false);
          setScale(1);
          updateSearchParams({ pdf_index: tempValue - 1 });
          setTempValue(tempValue - 1);
          setHoveredCellCoordinates(null);
          const nt = new Date().getTime();
          setSessionStartTime(nt);
        }}
      >
        <ArrowLeftCircleFill size={40} />
      </button>
      <span className="my-4 mx-2">
        <input
          value={tempValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          className="btn btn-secondary"
          style={{ width: "8ch" }}
        />
        <span className="my-4">
          {" "}
          <strong>/</strong>{" "}
          <input
            disabled
            value={`${totalInvoices}`}
            className="btn btn-secondary"
            style={{ width: "8ch", cursor: "default" }}
          />
        </span>
      </span>
      <button
        className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
        disabled={totalInvoices <= tempValue}
        onClick={() => {
          setPageNumber(tempValue + 1);
          setLockZoomAndScroll(false);
          setScale(1);
          updateSearchParams({ pdf_index: tempValue + 1 });
          setTempValue(tempValue + 1);
          setHoveredCellCoordinates(null);
          const nt = new Date().getTime();
          setSessionStartTime(nt);
        }}
      >
        <ArrowRightCircleFill size={40} />
      </button>
    </div>
  );
};
