import { create } from "zustand";
import { persist } from "zustand/middleware";
export const globalStoreV2 = create(
  persist(
    (set) => ({
      actual_vendor_name: "",
      vendorsToMerge: [],
      masterVendor: null,

      setMasterVendor: (id) => set({ masterVendor: id }),
      setVendorsToMerge: (vendors) => set({ vendorsToMerge: vendors }),
      setActual_vendor_name: (name) => set({ actual_vendor_name: name })
    }),
    {
      name: "global-persist-store", // Name for the storage (localStorage key)
      getStorage: () => localStorage // Use localStorage as the storage
    }
  )
);
