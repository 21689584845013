import React, { useState, useEffect } from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "./components/Header";
import {
  getVendorDetails,
  saveVendorDetails,
  useGetVendorDetails
} from "./api";
import { useParams } from "react-router-dom";
import { Spinner } from "components/common/Spinner";
import VendorDetailsTable from "./components/VendorDetails";
import { toast, ToastContainer } from "react-toastify";
import { transformInitialMapping } from "./transformationFunctions";
import useAppStore from "store/global";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { JournalCheck } from "react-bootstrap-icons";
import Notes from "components/Comments/Notes";
import { globalStoreV2 } from "store/globalStoreV2";

export default function VendorDetails() {
  const { vendorID } = useParams();
  const {actual_vendor_name}=globalStoreV2()
  const { data, isLoading } = useGetVendorDetails(vendorID);
  
  const tooltip = <Tooltip id="tooltip">View Vendor Notes</Tooltip>;
  const [showNotes, setShowNotes] = useState(false);
  return (
    <>
      <NavHeader view="Vendor Details" />

      {isLoading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <Header text={`Vendor Details for ${actual_vendor_name}`}>
            <OverlayTrigger placement="left" overlay={tooltip}>
              <Button
                variant="success"
                className="mx-2"
                onClick={() => setShowNotes(true)}
                disabled={vendorID === null}
              >
                <JournalCheck
                  height={20}
                  width={20}
                  fill="white"
                  className="cursor-pointer warning"
                />
              </Button>
            </OverlayTrigger>
          </Header>

          <VendorDetailsTable
            data={data}
            // handleSaveVendorDetails={handleSaveVendorDetails}
          />
          <Notes
            show={showNotes}
            setShow={setShowNotes}
            name="Vendor Notes"
            data={[]}
            vendorId={vendorID}
          />
        </>
      )}
      <ToastContainer />
    </>
  );
}
