import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { shared } from "use-broadcast-ts";

const useAppStore = createWithEqualityFn(
  shared((set) => ({
    numPages: null,
    tableData: [],
    isRunning: true,
    verification_time: 0,
    timer: 0,
    latest_update_info: {},
    pdfUrl: null,
    invoiceNum: "",
    invoiceDate: "",
    dueDate: null,
    vendorName: "",
    isVendorVerified: false,
    vendorPhone: "",
    vendorAddress: "",
    isAddressVerified: false,
    invoiceBalance: "",
    invoiceTotal: "",
    invoicePaymentTerms: "",
    invoiceRemitTo: "",
    invoiceRoute: "",
    invoiceShipTo: "",
    invoiceBillTo: "",
    invoiceType: "",
    invoiceGlobalAddresses: "",
    invoiceSoldTo: "",
    totalPagesRcvd: "",
    totalPagesInInvoice: "",
    totalPagesInInvoiceFromGlobal: "",
    vendorNamesSource: "",
    totalPagesProcessed: "",
    humanVerificationReqd: "",
    invoiceTotalFromtable: "",
    invoiceDiscount: "",
    invoiceTaxes: [],
    failedReasons: [],
    verdict: "",
    concerns: [],
    documentTypePrediction: null,
    loading: false,
    invoiceTableData: [],
    combinedTableData: [],
    searchInput: "",
    invoiceNumArray: [],
    searchResultVisible: false,
    extraChargesAdded: [],
    extraFeesAdded: [],
    extraDiscountsAdded: [],
    respData: {},
    selectedFilter: "All",
    additionalCols: [],
    categoryChoices: [],
    tableSpecificAddCols: [],
    additionalHeaders: [],
    numberOfRows: {},
    additionalColsTables: [],
    saved: false,
    getVerified: "both",
    status: true,
    showVertical: false,
    blobPath: null,
    pageNum: 1,
    scale: 1.0,
    rotation: 0,
    pdfSource: null,
    version: 1,
    invoiceUUID: null,
    restaurantsId: null,
    restaurantName: null,
    rerunStatus: null,
    categoryWiseSum: {},
    quickBooksDocType: null,
    branchId: null,
    vendorId: null,
    creditCardName: "",
    creditCardNumber: "",
    lockZoomAndScroll: false,
    isTimerRunning: false,
    lineItemMetaData: {},
    sessionStartTime: null,
    humanVerified: null,
    isRejected: false,
    clickbaconStatus: false,
    selectPdfPortion: false,
    acceptAndRejectControl: {},
    line_item_metadata:{},
    // actions: {
    setNumPages: (numPages) => set({ numPages }),
    setTableData: (tableData) => set({ tableData }),
    setPdfUrl: (pdfUrl) => set({ pdfUrl }),
    setInvoiceNum: (invoiceNum) => set({ invoiceNum }),
    setInvoiceDate: (invoiceDate) => set({ invoiceDate }),
    setdueDate: (dueDate) => set({ dueDate }),
    setvendorName: (vendorName) => set({ vendorName }),
    setIsVendorVerified: (isVendorVerified) => set({ isVendorVerified }),
    setVendorPhone: (vendorPhone) => set({ vendorPhone }),
    setVendorAddress: (vendorAddress) => set({ vendorAddress }),
    setIsAddressVerified: (isAddressVerified) => set({ isAddressVerified }),
    setInvoiceBalance: (invoiceBalance) => set({ invoiceBalance }),
    setInvoiceTotal: (invoiceTotal) => set({ invoiceTotal }),
    setInvoicePaymentTerms: (invoicePaymentTerms) =>
      set({ invoicePaymentTerms }),
    setInvoiceRemitTo: (invoiceRemitTo) => set({ invoiceRemitTo }),
    setInvoiceRoute: (invoiceRoute) => set({ invoiceRoute }),
    setInvoiceShipTo: (invoiceShipTo) => set({ invoiceShipTo }),
    setInvoiceBillTo: (invoiceBillTo) => set({ invoiceBillTo }),
    setInvoiceType: (invoiceType) => set({ invoiceType }),
    setInvoiceGlobalAddresses: (invoiceGlobalAddresses) =>
      set({ invoiceGlobalAddresses }),
    setInvoiceSoldTo: (invoiceSoldTo) => set({ invoiceSoldTo }),
    setTotalPagesRcvd: (totalPagesRcvd) => set({ totalPagesRcvd }),
    setTotalPagesInInvoice: (totalPagesInInvoice) =>
      set({ totalPagesInInvoice }),
    setTotalPagesInInvoiceFromGlobal: (totalPagesInInvoiceFromGlobal) =>
      set({ totalPagesInInvoiceFromGlobal }),
    setvendorNamesSource: (vendorNamesSource) => set({ vendorNamesSource }),
    setTotalPagesProcessed: (totalPagesProcessed) =>
      set({ totalPagesProcessed }),
    setHumanVerificationReqd: (humanVerificationReqd) =>
      set({ humanVerificationReqd }),
    setInvoiceTotalFromtable: (invoiceTotalFromtable) =>
      set({ invoiceTotalFromtable }),
    setInvoiceDiscount: (invoiceDiscount) => set({ invoiceDiscount }),
    setInvoiceTaxes: (invoiceTaxes) => set({ invoiceTaxes }),
    setFailedReasons: (failedReasons) => set({ failedReasons }),
    setVerdict: (verdict) => set({ verdict }),
    setConcerns: (concerns) => set({ concerns }),
    setDocumentTypePrediction: (documentTypePrediction) =>
      set({ documentTypePrediction }),
    setLoading: (loading) => set({ loading }),
    setInvoiceTableData: (invoiceTableData) => set({ invoiceTableData }),
    setCombinedTableData: (combinedTableData) => set({ combinedTableData }),
    setSearchInput: (searchInput) => set({ searchInput }),
    setInvoiceNumArray: (invoiceNumArray) => set({ invoiceNumArray }),
    setSearchResutsVisible: (searchResultVisible) =>
      set({ searchResultVisible }),
    setExtraChargesAdded: (extraChargesAdded) => set({ extraChargesAdded }),
    setExtraFeesAdded: (extraFeesAdded) => set({ extraFeesAdded }),
    setExtraDiscountsAdded: (extraDiscountsAdded) =>
      set({ extraDiscountsAdded }),
    setRespData: (respData) => set({ respData }),
    setSelectedFilter: (selectedFilter) => set({ selectedFilter }),
    setAdditionalCols: (additionalCols) => set({ additionalCols }),
    setTableSpecificAddCols: (tableSpecificAddCols) =>
      set({ tableSpecificAddCols }),
    setCategoryChoices: (categoryChoices) => set({ categoryChoices }),
    setAdditionalHeaders: (additionalHeaders) => set({ additionalHeaders }),
    setNumberOfRows: (numberOfRows) => set({ numberOfRows }),
    setAdditionalColsTables: (additionalColsTables) =>
      set({ additionalColsTables }),
    setSaved: (saved) => set({ saved }),
    setGetVerified: (getVerified) => set({ getVerified }),
    setStatus: (status) => set({ status }),
    setShowVertical: (showVertical) => set({ showVertical }),
    setBlobPath: (blobPath) => set({ blobPath }),
    setPageNum: (pageNum) => set({ pageNum }),
    setScale: (scale) => set({ scale }),
    setRotation: (rotation) => set({ rotation }),
    setPdfSource: (pdfSource) => set({ pdfSource }),
    setVersion: (version) => set({ version }),
    setInvoiceUUID: (invoiceUUID) => set({ invoiceUUID }),
    setRestaurantsId: (restaurantsId) => set({ restaurantsId }),
    setRerunStatus: (rerunStatus) => set({ rerunStatus }),
    setCategoryWiseSum: (categoryWiseSum) => set({ categoryWiseSum }),
    setQuickBooksDocType: (quickBooksDocType) => set({ quickBooksDocType }),
    setBranchId: (branchId) => set({ branchId }, console.log(branchId)),
    setVendorId: (vendorId) => set({ vendorId }),
    setCreditCardName: (creditCardName) => set({ creditCardName }),
    setCreditCardNumber: (creditCardNumber) => set({ creditCardNumber }),
    setLockZoomAndScroll: (lockZoomAndScroll) => set({ lockZoomAndScroll }),
    setLatestUpdateInfo: (info) => set({ latest_update_info: info }),
    setIsRunning: (stat) => set({ isRunning: stat }),
    setTimer: (timer) => set({ timer: timer }),
    setIsTimerRunning: (isTimerRunning) => set({ isTimerRunning }),
    setVerificationTime: () =>
      set((state) => ({ verification_time: state.verification_time + 1 })),
    doTimerZero: () => set({ verification_time: 0 }),
    setLineItemMetaData: (lineItemMetaData) => set({ lineItemMetaData }),
    setSessionStartTime: (sessionStartTime) => set({ sessionStartTime }),
    setHumanVerified: (humanVerified) => set({ humanVerified }),
    setIsRejected: (isRejected) => set({ isRejected }), 
    setClickbaconStatus: (clickbaconStatus) => set({ clickbaconStatus }),
    setRestaurantName: (restaurantName) => set({ restaurantName }),
    setSelectPdfPortion: (selectPdfPortion) => set({ selectPdfPortion }),
    setAcceptAndRejectControl: (acceptAndRejectControl) =>
      set({ acceptAndRejectControl })
  })),
  Object.is
);

// TODO: refactor store or (use hooks to export all actions)
// export const useStoreActions = () => useAppStore((state) => state.actions);

// TODO: refactor store or (use hooks with shallow)

export const useVerificationParams = () =>
  useAppStore(
    (state) => ({
      human_verified: state.getVerified,
      human_verification:
        state.selectedFilter === "All"
          ? "both"
          : state.selectedFilter === "Human Verification Required"
          ? "true"
          : "false"
    }),
    shallow
  );

export default useAppStore;
