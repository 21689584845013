import { Spinner } from "components/common/Spinner";
import { Spinner as Loader } from "react-bootstrap";
import { NavHeader } from "components/layout/NavHeader";
import useUpdateParams from "hooks/useUpdateParams";
import { useState } from "react";
import { Dropdown, Modal, ProgressBar } from "react-bootstrap";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
  PlusCircleFill,
  Search
} from "react-bootstrap-icons";
import { useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useAddVendor, useDeleteVendor, useGetVendorListing } from "./api";
import Header from "./components/Header";
import VendorTable from "./components/VendorTable";
export default function Home() {
  const [searchParams] = useSearchParams();
  const updateParams = useUpdateParams();
  let human_verified = searchParams.get("human_verified") || "all";
  let vendor_category = searchParams.get("vendor_category") || "";
  let page = searchParams.get("page") || 1;
  let page_size = searchParams.get("page_size") || 10;
  let vendor_name_search = searchParams.get("vendor_name_search") || "";
  const [searchTerm, setSearchTerm] = useState(vendor_name_search);
  const [page_index, setPageIndex] = useState(page);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [isAdding, setIsAdding] = useState(false);

  let verified_by = searchParams.get("verified_by") || "all";
  const { data, isLoading } = useGetVendorListing({
    human_verified,
    vendor_category,
    page,
    page_size,
    vendor_name_search,
    verified_by
  });

  const { mutate: deleteVendor, isPending: deletingVendor } = useDeleteVendor();
  const { mutate: addVendor, isPending: addingVendor } = useAddVendor();
  const handleDeleteVendor = (vendor_id) => {
    setIsDeleting(true);
    deleteVendor(vendor_id, {
      onSettled: () => {
        setIsDeleting(false);
      }
    });
  };
  const vendors = data?.data?.vendors;
  const total_pages = data?.total_pages;

  return (
    <>
      <NavHeader view="Vendors" />
      <ToastContainer />
      <Header
        text={"Vendors"}
        children={
          <div className="d-flex justify-content-end">
            <span
              className="mr-3"
              style={{ display: "flex", flexDirection: "row" }}
            >
              Total: {!isLoading ? data?.data?.total_vendor_count : 0}{" "}
            </span>
            <ProgressBar
              now={0}
              max={0}
              label={`${isLoading ? 0 : data?.data?.verified_vendor_count}`}
              variant="success"
              style={{
                backgroundColor: "white",
                width: "300px",
                marginTop: "5px",
                marginLeft: "10px"
              }}
            />
          </div>
        }
      />
      <div className="row mt-3 mx-5">
        <div className="col-md-6 d-flex">
          <input
            type="text"
            value={searchTerm}
            className="form-control"
            placeholder="Search by vendor name"
            onChange={(e) => {
              if (e.target.value == "") {
                updateParams({ vendor_name_search: undefined });
                setSearchTerm("");
              } else {
                setSearchTerm(e.target.value);
              }
            }}
          />
          <button
            className="btn btn-light"
            onClick={() => {
              updateParams({ vendor_name_search: searchTerm });
              // setSearchTerm("");
            }}
          >
            <Search />
          </button>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <PlusCircleFill className="mx-2" />
            Add Vendor
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-end mx-5 mt-2 mb-0">
        <Dropdown className="mx-2">
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            style={{ textTransform: "capitalize" }}
          >
            {/* {humanVerifiedFilter === null
              ? "Human Verified"
              : humanVerifiedFilter
              ? "Verified"
              : "Not Verified"} */}
            {human_verified !== "all" ? human_verified : "Human Verified"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              value={human_verified}
              onClick={() => {
                // setHumanVerifiedFilter(true);
                updateParams({ human_verified: true });
              }}
            >
              Verified
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                // setHumanVerifiedFilter(null);
                updateParams({ human_verified: false });
              }}
            >
              Not Verified
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                // setHumanVerifiedFilter(null);
                updateParams({ human_verified: "all" });
              }}
            >
              Clear
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* {!userDataLoading&& (
          <CustomDropdown
            data={[...userData?.users, { user_uuid: null, username: "None" }]}
            Objectkey={"user_uuid"}
            displayKey={"username"}
            className="w-auto"
            maxWidth="180px"
            minWidth="180px"
            value={
              // userData?.users?.find(
              //   (user) => user.user_uuid === verifiedByFilter
              // )?.username || "Verified By"
              "amna"
            }
            onSelect={(v) => {
            
            }}
          />
        )} */}
        {/* <Dropdown className="mx-2">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {verifiedByFilter === null
              ? "Verified By"
              : userData?.users?.find(
                  (user) => user.user_uuid === verifiedByFilter
                )?.username || "None"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {userDataLoading && (
              <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                <Spinner />
              </div>
            )}
            {!userDataLoading &&
              userData &&
              userData?.users?.map((user) => (
                <Dropdown.Item
                  onClick={() => {
                    setVerifiedByFilter(user.user_uuid);
                  }}
                >
                  {user.username}
                </Dropdown.Item>
              ))}
            <Dropdown.Item
              onClick={() => {
                setVerifiedByFilter(null);
              }}
            >
              None
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <Dropdown className="mx-2">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {vendor_category == "" ? "Vendor Category" : vendor_category}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {["NA", "Normal Invoice", "Liquor Invoice", "Summary Invoice"].map(
              (category) => (
                <Dropdown.Item
                  onClick={() => {
                    updateParams({
                      vendor_category: category == "NA" ? "" : category
                    });
                  }}
                >
                  {category === null ? "None" : category}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {isLoading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <VendorTable
            data={vendors}
            handleDeleteVendor={handleDeleteVendor}
            isDeleting={isDeleting}
          />
          <div className="row">
            <div className="mt-4 mb-2">
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={page_index == 1 || total_pages == 1}
                onClick={() => {
                  if (page_index > 1) {
                    setPageIndex((prev) => Number(prev) - 1);
                    updateParams({ page: Number(page_index) - 1 });
                  }
                }}
              >
                <ArrowLeftCircleFill size={40} />
              </button>
              <span className="my-4 mx-2">
                <input
                  value={page_index}
                  onChange={(e) => {
                    setPageIndex(e.target.value);
                  }}
                  onBlur={() => {
                    if (page_index >= total_pages) {
                      setPageIndex(total_pages);
                      updateParams({ page: total_pages });
                    } else {
                      updateParams({ page: page_index });
                    }
                  }}
                  className="btn btn-secondary"
                  style={{ width: "7ch" }}
                />
                <span className="my-4">
                  {" "}
                  <strong>/</strong>{" "}
                  <input
                    disabled
                    value={`${data?.total_pages}`}
                    className="btn btn-secondary"
                    style={{ width: "7ch", cursor: "default" }}
                  />
                </span>
              </span>
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={0}
                onClick={() => {
                  if (page_index < total_pages) {
                    setPageIndex((prev) => Number(prev) + 1);
                    updateParams({ page: Number(page_index) + 1 });
                  }
                }}
              >
                <ArrowRightCircleFill size={40} />
              </button>
            </div>
          </div>
        </>
      )}

      <Modal
        centered
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Vendor Name</label>
            <input
              type="text"
              className="form-control"
              value={vendorName}
              onChange={(e) => setVendorName(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => {
              setIsAdding(true);
              addVendor(vendorName, {
                onSuccess: (data) => {
                  setVendorName("");
                  setIsAdding(false);
                  setShowModal(false);
                  toast.success(data?.message, {
                    autoClose: 2000
                  });
                },
                onSettled: () => {
                  setIsAdding(false);
                }
              });
            }}
            disabled={addingVendor}
          >
            {isAdding ? (
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5
                }}
              >
                {" "}
                <Loader style={{ height: 20, width: 20 }} />
                Adding...
              </span>
            ) : (
              "Add"
            )}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setShowModal(false);
              setVendorName("");
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
