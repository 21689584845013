import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "config/env";
import { axiosInstance, instance } from "lib/axios";
import { queryClient } from "lib/react-query";
import { toast } from "react-toastify";

// Vendor Consolidation
export const useGetVendorListing = (payload) => {
  return useQuery({
    queryKey: ["vendor-listing", payload],
    queryFn: async () => {
      const {
        vendor_category,
        human_verified,
        page,
        page_size,
        verified_by,
        vendor_name_search
      } = payload;
      const apiUrl = `/api/vendor/?vendor_category=${vendor_category}&human_verified=${human_verified}&page=${page}&page_size=${page_size}&verified_by=${verified_by}&vendor_name_search=${vendor_name_search}`;
      try {
        //
        const response = await instance.get(apiUrl);
        return response.data;
      } catch (error) {
        console.log(error);
        return error?.response?.data;
      }
    }
  });
};

export const useAddVendor = () => {
  return useMutation({
    mutationFn: async (vendor_name) => {
      const response = await instance.post(`/api/vendor/create/`, {
        vendor_name
      });
      return response;
    },
    onError: (data) => {
      toast.error(data?.message);
    },
    onSuccess: (data) => {
      toast.success(data?.message);
      queryClient.invalidateQueries({ queryKey: ["vendor-listing"] });
    }
  });
};

export const useDeleteVendor = () => {
  return useMutation({
    mutationFn: async (vendor_id) => {
      const response = await instance.delete(
        `/api/vendor/${vendor_id}/delete/`
      );
      return response;
    },
    onSuccess: (data) => {
      toast.success(data?.message, {
        autoClose: 3000
      });
      queryClient.invalidateQueries({ queryKey: ["vendor-listing"] });
    }
  });
};

// Vendor Details
export const useGetVendorDetails = (vendor_id) => {
  return useQuery({
    queryKey: ["vendor-details", vendor_id],
    queryFn: async () => {
      try {
        const response = await instance.get(
          `/api/vendor/${vendor_id}/details/`
        );
        return response?.data;
      } catch (error) {
        console.log(`Error in Getting Vendor Details`, error);
        return error?.response?.data;
      }
    }
  });
};

export const useGetAdditionalData = () => {
  return useQuery({
    queryKey: ["additional-data"],
    queryFn: async () => {
      const apiUrl = `/api/utils/additional_data?category_choices=true&processed_table_header_candidates=true&vendor_invoice_document_types=true&vendor_invoice_categories=true`;

      try {
        const response = await instance.get(apiUrl);
        return response?.data;
      } catch (error) {
        console.log(`error in fetching additional data`, error);
        return error?.response?.data;
      }
    }
  });
};

export const useGetInvoiceHeaderMappingException = (vendor_id) => {
  return useQuery({
    queryKey: ["invoice-header-mapping-exceptions", vendor_id],
    queryFn: async () => {
      try {
        const response = await instance.get(
          `/api/vendor/${vendor_id}/invoice-header-exceptions/`
        );
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};
export const useUpdateInvoiceHeaderMappingException = () => {
  return useMutation({
    mutationFn: async ({ vendor_id, invoice_header_exceptions }) => {
      const response = await instance.post(
        `/api/vendor/${vendor_id}/invoice-header-exceptions/`,
        {
          invoice_header_exceptions
        }
      );
      return response?.data;
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message, {
        autoClose: 3000
      });
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${
          data?.data?.updated_fields?.length > 0
            ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
            : ``
        }`,
        {
          autoClose: 3000
        }
      );
    }
  });
};
export const useUpdateVendorDetails = () => {
  return useMutation({
    mutationFn: async ({ vendor_id, data }) => {
      const response = await instance.put(`/api/vendor/${vendor_id}/details/`, {
        ...data
      });

      return response.data;
    },
    onError: (data) => {
      toast.error(data?.message, {
        autoClose: 2000
      });
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${
          data?.data?.updated_fields?.length > 0
            ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
            : ``
        }`,
        {
          autoClose: 2000
        }
      );
    }
  });
};

// Combine Vendors
export const useGetSimilarVendors = ({ vendor_id, similarity }) => {
  return useQuery({
    queryKey: ["similar-vendors", vendor_id, similarity],
    queryFn: async () => {
      try {
        const response = await instance.get(
          `/api/vendor/${vendor_id}/similar-vendors/?similarity=${similarity}`
        );
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useCombineVendors = () => {
  return useMutation({
    mutationFn: async ({ vendor_id, vendors_to_combine }) => {
      const response = await instance.post(
        `/api/vendor/${vendor_id}/combine/`,
        { vendors_to_combine }
      );
      return response?.data;
    },
    onError: (data) => {
      toast.error(data?.message, {
        autoClose: 2000
      });
    },
    onSuccess: (data) => {
      toast.success(data?.message, {
        autoClose: 2000
      });
      queryClient.invalidateQueries({ queryKey: ["similar-vendors"] });
    }
  });
};

// Vendor Branche
export const useGetVendorBranches = (payload) => {
  return useQuery({
    queryKey: ["vendor-branches", payload],
    queryFn: async () => {
      try {
        const response = await instance.get(
          `/api/vendor-branch/${payload?.vendor_id}/?vendor_address=${payload?.vendor_address}&page=${payload?.page}`
        );
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useUpdateBranchDetails = () => {
  return useMutation({
    mutationFn: async ({ branch_id, payload }) => {
      const response = await instance.put(
        `/api/vendor-branch/${branch_id}/details/`,
        { ...payload }
      );
      return response?.data;
    },
    onError: (data) => {
      toast.error(data?.message, {
        autoClose: 2000
      });
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${
          data?.data?.updated_fields?.length > 0
            ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
            : ``
        }`,
        {
          autoClose: 2000
        }
      );
      queryClient.invalidateQueries({ queryKey: ["vendor-branches"] });
      queryClient.invalidateQueries({ queryKey: ["vendor-branch-details"] });
    }
  });
};

export const useCombineVendorBranches = () => {
  return useMutation({
    mutationFn: async ({ branch_id, branches_to_merge }) => {
      const response = await instance.post(
        `/api/vendor-branch/${branch_id}/combine/`,
        {
          branches_to_combine: branches_to_merge
        }
      );
      return response.data;
    },
    onError: (data) => {
      toast.error(data?.message, {
        autoClose: 2000
      });
    },
    onSuccess: (data) => {
      toast.success(data?.message, {
        autoClose: 2000
      });
      queryClient.invalidateQueries({ queryKey: ["vendor-branches"] });
    }
  });
};

export const useMigrateVendorBranch = () => {
  return useMutation({
    mutationFn: async ({ branch_id, new_vendor_id }) => {
      const response = await instance.post(
        `/api/vendor-branch/${branch_id}/migrate/`,
        {
          new_vendor_id
        }
      );
      return response.data;
    },
    onError: (data) => {
      toast.error(data?.message, {
        autoClose: 2000
      });
    },
    onSuccess: (data) => {
      toast.success(data?.message, {
        autoClose: 2000
      });
      queryClient.invalidateQueries({ queryKey: ["vendor-branches"] });
    }
  });
};

export const useDeleteVendorBranch = () => {
  return useMutation({
    mutationFn: async (branch_id) => {
      const response = await instance.delete(
        `/api/vendor-branch/${branch_id}/delete/`
      );
      return response.data;
    },
    onError: (data) => {},
    onSuccess: (data) => {
      toast.success(data?.message, {
        autoClose: 2000
      });
      queryClient.invalidateQueries({ queryKey: ["vendor-branches"] });
    }
  });
};

export const useGetBranchDetails = (branch_id) => {
  return useQuery({
    queryKey: ["vendor-branch-details", branch_id],
    queryFn: async () => {
      try {
        const response = await instance.get(
          `/api/vendor-branch/${branch_id}/details/`
        );
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useGetVendorNames = () => {
  return useQuery({
    queryKey: ["vendor-names"],
    queryFn: async () => {
      try {
        const response = await instance.get(`/api/vendor/names/`);
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

const getVendorDetails = async (vendorId) => {
  const body = {
    vendor_id: vendorId
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_vendor_details`,
    body
  );
  return response.data;
};

const getVendorBranchDetails = async (vendorId, branchId) => {
  const body = {
    vendor_id: vendorId,
    branch_id: branchId
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/get_vendor_branch_details`,
    body
  );
  return response.data;
};

const saveVendorDetails = async (vendorDetails) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/save_vendor_details`,
    vendorDetails
  );
  return response.data;
};

const getVendorBranches = async (body) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_all_vendor_branch_data`,
    body
  );
  return response.data;
};

const saveBranchDetails = async (branchDetails) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/save_vendor_branch_details`,
    branchDetails
  );
  return response.data;
};

const findSimilarVendors = async (similarity, vendorId) => {
  const body = {
    similarity,
    vendor_id: vendorId
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_similar_vendors_data`,
    body
  );
  return response.data;
};

const combineVendors = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/combine_vendors`,
    data
  );
  return response.data;
};

const combineVendorBranches = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/combine_vendor_branches`,
    data
  );
  return response.data;
};

const getVendorBranchPdfs = async (vendorId, branchId) => {
  const body = {
    vendor_id: vendorId,
    branch_id: branchId
  };
  const response = await axiosInstance.post(
    `${API_URL}/get_vendor_branch_pdfs`,
    body
  );
  return response.data;
};

const renameVendor = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/rename_vendor_branch_address`,
    data
  );
  return response.data;
};

const getItemsForVendor = async (body) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_item_master_for_vendor`,
    body
  );
  return response.data;
};

const approveItem = async (body) => {
  // const body = {
  //   item_uuid,
  //   category,
  //   branch_id: branchId,
  //   human_verified: humanVerified,
  //   item_code: itemCode,
  //   item_description: itemDescription,
  // };
  const response = await axiosInstance.post(
    `${API_URL}/update_item_master_details`,
    body
  );
  return response.data;
};

const getItemPdfs = async (item_uuid, vendor_id) => {
  const body = {
    item_uuid,
    vendor_id
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_item_master_pdf_links`,
    body
  );
  return response.data;
};

const deleteVendor = async (vendorId) => {
  const body = {
    vendor_id: vendorId
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/delete_vendor`,
    body
  );
  return response.data;
};

const deleteVendorBranch = async (vendorId, branchId) => {
  const body = {
    vendor_id: vendorId,
    branch_id: branchId
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/delete_vendor_branch`,
    body
  );
  return response.data;
};

const addNewVendor = async (vendorName) => {
  const body = {
    vendor_name: vendorName
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/create_vendor`,
    body
  );
  return response.data;
};

const getAdditionalData = async () => {
  const body = {
    category_choices: true,
    common_issues_in_invoice_extraction: false,
    processed_table_header_candidates: true
  };
  const response = await axiosInstance.post(
    `${API_URL}/get_additional_data`,
    body
  );
  return response.data;
};

const mergeItemMaster = async (
  vendorId,
  branchId,
  masterItemUUID,
  itemsToMerge
) => {
  const data = {
    vendor_id: vendorId,
    branch_id: branchId,
    master_item_uuid: masterItemUUID,
    items_to_merge: itemsToMerge
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/combine_item_masters`,
    data
  );
  return response.data;
};

const deleteItemMaster = async (vendorId, branchId, itemUUID, softDelete) => {
  const data = {
    vendor_id: vendorId,
    branch_id: branchId,
    item_uuid: itemUUID,
    soft_delete: softDelete
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/delete_item_master`,
    data
  );
  return response.data;
};

const migrateBranch = async (payload) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/migrate_branch_vendor`,
    payload
  );
  return response.data;
};

const disapproveAllItems = async (vendorId) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/unverify_all_items`,
    { vendor_id: vendorId }
  );
  return response.data;
};

const createOrUpdateItemMasterIssues = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/engineering_issues/create_or_update_item_master_issue`,
    data
  );
  return response.data;
};

export const useFetchItemMasterIssues = (itemList) => {
  return useQuery({
    placeholderData: (previousData, q) => previousData,
    queryKey: ["itemMasterIssues", itemList?.[0]?.item_uuid],
    queryFn: async () => {
      try {
        const body = {
          item_uuid: itemList[0].item_uuid
        };
        const response = await axiosInstance.post(
          `/engineering_issues/get_item_master_issue`,
          body
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.ensureQueryData(
        ["itemMasterIssues", itemList[0]?.item_uuid],
        data
      );
    },
    enabled: !!itemList?.length > 0
  });
};

export const useGetInvoiceHeaderExceptionsForVendor = (vendorId) => {
  return useQuery({
    queryKey: ["invoiceHeaderExceptions", vendorId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL}/vendor_page/get_invoice_header_exceptions?vendor_id=${vendorId}`
      );
      return response.data;
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.setQueryData(
        ["invoiceHeaderExceptions", vendorId],
        data
      );
    },
    enabled: !!vendorId
  });
};

export const useUpdateInvoiceHeaderExceptionsForVendor = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axiosInstance.post(
        `${API_URL}/vendor_page/update_invoice_header_exceptions`,
        data
      );
      return response.data;
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([
        "invoiceHeaderExceptions",
        variables.vendorId
      ]);
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error("Error updating invoice header exceptions");
    }
  });
};

export {
  addNewVendor,
  approveItem,
  combineVendorBranches,
  combineVendors,
  createOrUpdateItemMasterIssues,
  deleteItemMaster,
  deleteVendor,
  deleteVendorBranch,
  disapproveAllItems,
  findSimilarVendors,
  getAdditionalData,
  getItemPdfs,
  getItemsForVendor,
  getVendorBranchDetails,
  getVendorBranches,
  getVendorBranchPdfs,
  // getVendorConsolidation,
  getVendorDetails,
  mergeItemMaster,
  migrateBranch,
  renameVendor,
  saveBranchDetails,
  saveVendorDetails
};
