import React, { useState, useEffect } from "react";
import {
  getItemsForVendor,
  getAdditionalData,
  approveItem,
  mergeItemMaster,
  getVendorDetails,
  saveVendorDetails,
  createOrUpdateItemMasterIssues,
  useFetchItemMasterIssues,
} from "./api";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { NavHeader } from "components/layout/NavHeader";
import { Spinner } from "components/common/Spinner";
import Header from "./components/Header";
import { toast, ToastContainer } from "react-toastify";
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import useAppStore from "store/global";
import FastItemMasterVerificationTable from "./components/FastItemVerificationTable";
import { Accordion, Button, Dropdown, ListGroup, Modal } from "react-bootstrap";
import { axiosInstance } from "lib/axios";
import { transformInitialMapping } from "./transformationFunctions";
import {
  PlusCircleFill,
  Trash3Fill,
  ArrowRightCircle,
  ArrowLeftCircle,
} from "react-bootstrap-icons";
import { ProgressBar } from "react-bootstrap";
import IssueSubmitScreen from "components/IssueSubmission/IssueSubmitScreen";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "lib/react-query";
export default function FastItemMasterVerification() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [vendorDetails, setvendorDetails] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [verifiedItems, setVerifiedItems] = useState(0);
  const [requiredItemColumns, setRequiredItemColumns] = useState([]);
  const [categoryChoices, setCategoryChoices] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const pageIndex = searchParams.get("page_index") ?? 1;
  const vendorName = searchParams.get("vendor_name");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("Item Description");
  const [search, setSearch] = useState(false);
  const { vendorId } = useParams();
  const [approveLoading, setApproveLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const [hoveredCellCoordinates, setHoveredCellCoordinates] = useState([]);
  const { setPageNum, scale, setScale } = useAppStore();
  const [wholeList, setWholeList] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [masterItem, setMasterItem] = useState({});
  const [similarItems, setSimilarItems] = useState([]);
  const [catReviewToggle,setCatReviewToggle]=useState(false)
  const [selectedItems, setSelectedItems] = useState([]);
  const {
    sessionStartTime,
    setSessionStartTime
  } = useAppStore();

  const [mergeData, setMergeData] = useState({
    vendorId: "",
    branchId: "",
    masterItemUUID: "",
    itemsToMerge: "",
  });
  const calculatePixelCoordinates = (
    normalizedCoordinates,
    pageWidth,
    pageHeight,
    scale
  ) => {
    const pixelCoordinates = normalizedCoordinates.map(({ X, Y }) => ({
      x: X * pageWidth * scale,
      y: Y * pageHeight * scale,
    }));
    return pixelCoordinates;
  };

  useEffect(() => {
    const nt = new Date().getTime();
    setSessionStartTime(nt);

    return () => {
      setSessionStartTime(null);
    }
  }, [pageIndex]);

  function handleMultipleCellHover(
    normalizedCoordinatesArray,
    pageNumber,
    pdfUrl
  ) {
    if (
      !normalizedCoordinatesArray ||
      normalizedCoordinatesArray.length === 0 ||
      normalizedCoordinatesArray === null
    ) {
      setHoveredCellCoordinates([]);
      return;
    }
    setPageNum(pageNumber);
    pdfjs
      .getDocument(pdfUrl)
      .promise.then((pdf) => {
        const promises = normalizedCoordinatesArray.map(
          async (row, rowIndex) => {
            const page = await pdf.getPage(pageNumber);
            const pageWidth = page.view[2];
            const pageHeight = page.view[3];

            const coordinates = normalizedCoordinatesArray.map(
              (normalizedCoordinates) => {
                const pixelCoordinates = calculatePixelCoordinates(
                  normalizedCoordinates,
                  pageWidth,
                  pageHeight,
                  scale
                );
                return {
                  coordinates: pixelCoordinates,
                };
              }
            );

            return coordinates;
          }
        );

        Promise.all(promises)
          .then((boundingBoxes) => {
            const flattenedCoordinates = boundingBoxes.flat();
            setHoveredCellCoordinates(flattenedCoordinates);
            const reactPdfWrapper =
              document.getElementById("react-pdf__Wrapper");
            if (reactPdfWrapper) {
              reactPdfWrapper.scroll({
                top: flattenedCoordinates[0].coordinates[0].y - 75,
                left: flattenedCoordinates[0].coordinates[0].x - 50,
                behavior: "smooth",
              });
            }
          })
          .catch((error) => {
            setPageNum(1)
            console.error("Error fetching PDF page dimensions:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error);
      });
  }

  const handleGetAdditionalData = () => {
    getAdditionalData()
      .then((res) => {
        setCategoryChoices(res.category_choices);
      })
      .catch((err) => {});
  };

  const getItemList = (documentUUID, fetchingInvoiceItems, getSimilarItems) => {
    if (fetchingInvoiceItems === false) {
      setLoading(true);
    }
    if (getSimilarItems){
      setWholeList(true);
    }
    setLoaded(false);
    const body = {
      page_index: getSimilarItems? 1: parseInt(pageIndex),
      page_size: getSimilarItems ? 100 : 1,
      vendor_id: vendorId,
      document_uuid: documentUUID,
      search_filter: {
        item_description:
          searchBy === "Item Description"
            ? searchTerm === ""
              ? null
              : searchTerm
            : null,
        item_code:
          searchBy === "Item Code"
            ? searchTerm === ""
              ? null
              : searchTerm
            : null,
      },
      sort_by: {
        item_description: "desc",
        item_code: "desc",
      },
      date_filter: {
        start_date: startDate ? startDate : null,
        end_date: endDate ? endDate : null,
      },
      only_with_bounding_box: true,
    };
    getItemsForVendor(body)
      .then((res) => {
        setItemList(res.items);
        setTotalPages(res.total_pages);
        setTotalItems(res.total_items);
        setVerifiedItems(res.verified_items_count);
        setRequiredItemColumns(res.required_columns);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSaveVendorDetails = (data) => {
    setLoading(true);
    vendorDetails.invoice_header_names_mapping = transformInitialMapping(
      vendorDetails.invoice_header_names_mapping,
      true
    );

    saveVendorDetails(data)
      .then((res) => {
        toast.success(res.message);
        getvendorDetails();
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error saving vendor details");
        setLoading(false);
      });
  };

  const handleCategoryChange = async (body, docId) => {
    setApproveLoading(true);
    body.vendor_id = vendorId;
    body.verification_time = (new Date().getTime() - sessionStartTime) / 1000;
    approveItem(body)
      .then((data) => {
        toast.success(data.message);
        setApproved(true);
        setApproveLoading(false);
      })
      .catch((err) => {
        setApproveLoading(false);
        toast.error(err.response.data.message);
      });

    const payload = {
      item_uuid: body.item_uuid,
      vendor_id: body.vendor_id,
      branch_id: body.branch_id,
    };
    setMergeData({
      vendorId: vendorId,
      branchId: body.branch_id,
      masterItemUUID: body.item_uuid,
      itemsToMerge: selectedItems,
    });
    const response = await axiosInstance.post(
      "/vendor_page/find_similar_line_items",
      payload
    );
    setSimilarItems(response?.data?.similar_line_items);
    setMasterItem(response?.data?.searched_item);
    if (response?.data?.similar_line_items.length > 0) {
      setShowModal(true);
    } else {
      getItemList(null, false);
    }
  };

  useEffect(() => {
    getItemList();
    handleGetAdditionalData();
  }, [pageIndex]);

  useEffect(() => {
    getvendorDetails();
  }, [vendorId]);


  const [editableCell, setEditableCell] = useState(null);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleEditableCellBlur = () => {
    handleCategoryChange(rowToEdit ? rowToEdit : itemList[0]);
    setEditableCell(null);
    setRowToEdit(null);
  };

  const handleCheck = (status, item_uuid) => {
    if (status) {
      setSelectedItems((prev) => [...prev, item_uuid]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item != item_uuid));
    }
  };
  const mergeHanler = () => {
    setMergeData((prev) => ({ ...prev, itemsToMerge: selectedItems }));
    if (selectedItems.length == 0) {
      return toast.error("Select atleast one of the items to merge.");
    }
    mergeItemMaster(
      mergeData.vendorId,
      mergeData.branchId,
      mergeData.masterItemUUID,
      selectedItems
    )
      .then((res) => {
        toast.success(res?.message);
        setSelectedItems([]);
        setMasterItem({});
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
    setShowModal(false);
    getItemList(null, false);
    setLoaded(false);
    setApproved(false);
  };

  const getvendorDetails = () => {
    setLoading(true);
    getVendorDetails(vendorId)
      .then((res) => {
        const desiredOrder = [
          "vendor_id",
          "vendor_name",
          "human_verified",
          "document_count",
          "vendor_branches_count",
          "verified_branches_count",
          "total_items",
          "verified_items_count",
          "vendor_category",
          "vendor_gl_code",
          "vendor_name_synonyms",
          "vendor_payment_terms",
          "metadata",
          "required_invoice_columns_for_item_master",
          "invoice_header_names_mapping",
        ];
        const orderedVendorDetails = {};
        desiredOrder.forEach((key) => {
          if (res.hasOwnProperty(key)) {
            orderedVendorDetails[key] = res[key];
          }
        });
        orderedVendorDetails.invoice_header_names_mapping =
          transformInitialMapping(
            orderedVendorDetails.invoice_header_names_mapping,
            false
          );
        setvendorDetails(orderedVendorDetails);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const updateSearchParam = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const useIssueSubmission = () => {};

  const { mutate, isPending } = useMutation({
    mutationFn: createOrUpdateItemMasterIssues,
    onSuccess: (data) => {
      toast.success(data.message);
    },
    onError: (data) => {
      toast.error(data?.response?.data.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries("createOrUpdateItemMasterIssues");
    },
  });

  const {
    data: issuesData,
    isLoading: issuesDataLoading,
    error: issuesDataError,
  } = useFetchItemMasterIssues(itemList);

  const items = wholeList ? itemList : itemList?.slice(currentItemIndex, currentItemIndex + 1);
  // console.log("the items in parent are", items)
  return (
    <>
      <NavHeader view="Vendor Items" />
      <ToastContainer />
      <Header
      className="sticky-top"
        text={`Fast Item Verification`}
        children={
          <div className="d-flex justify-content-end">
            <span className="mr-3">Total: {totalItems}</span>
            <ProgressBar
              now={verifiedItems * 100}
              max={totalItems * 100}
              label={`${verifiedItems}/${totalItems}`}
              variant="success"
              style={{
                backgroundColor: "white",
                width: "300px",
                marginTop: "5px",
                marginLeft: "10px",
              }}
            />
          </div>
        }
      />
      {loading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px",
          }}
        >
          <Spinner />
        </div>
      )}

      {!loading && (
        <div className="mx-5 my-4 bg-white ">
        <div style={{width:'100%'}} className=" z-3">
        <FastItemMasterVerificationTable
            categoryChoices={categoryChoices}
            editableCell={editableCell}
            rowToEdit={rowToEdit}
            setRowToEdit={setRowToEdit}
            setEditableCell={setEditableCell}
            items={items}
            setItemList={setItemList}
            approveLoading={approveLoading}
            handleCategoryChange={handleCategoryChange}
            handleMultipleCellHover={handleMultipleCellHover}
            hoveredCellCoordinates={hoveredCellCoordinates}
            getItemList={getItemList}
            requiredItemColumns={requiredItemColumns}
            loaded={loaded}
            setLoaded={setLoaded}
            approved={approved}
            wholeList={wholeList}
            catReviewToggle={catReviewToggle}
            setCatReviewToggle={setCatReviewToggle}
            setWholeList={setWholeList}
          />
        </div>
          <div className="d-flex justify-content-between ">
            <div
              className="mr-3 text-primary"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/vendor-consolidation/${vendorId}`);
              }}
            >
              Back to Vendor
            </div>
          <div className="d-flex gap-3">
       
            <span className="mr-3 text-muted">
              Page: {pageIndex} of {totalPages}
            </span>
          </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary mx-3"
              disabled={pageIndex === 1}
              onClick={() => {
                // setCurrentItemIndex(currentItemIndex - 1);
                setLoaded(false);
                setApproved(false);
                // setPageIndex(pageIndex - 1);
                updateSearchParam("page_index", parseInt(pageIndex) - 1);
              }}
            >
              <ArrowLeftCircle />
            </button>
            <button
              className="btn btn-primary mx-3"
              onClick={() => {
                //   if (currentItemIndex === itemList?.length - 1) {
                //     setPageIndex(pageIndex + 1);
                //     setCurrentItemIndex(0);
                //   } else {
                //     setCurrentItemIndex(currentItemIndex + 1);
                //   }
                //   setLoaded(false);
                //   setIsTimerRunning(true);
                // }}
                if (pageIndex === totalPages) {
                  return;
                }
                // setPageIndex(pageIndex + 1);
                updateSearchParam("page_index", parseInt(pageIndex) + 1);
                setLoaded(false);
                setApproved(false);
              }}
              // disabled={
              //   currentItemIndex === itemList?.length - 1 &&
              //   pageIndex === totalPages
              // }
              disabled={pageIndex === totalPages}
            >
              <ArrowRightCircle />
            </button>

            <button
              className="btn btn-primary mx-3"
              onClick={() => {
                handleEditableCellBlur();
              }}
            >
              Save
            </button>
            {rowToEdit && (
              <button
                className="btn btn-danger mx-3"
                onClick={() => {
                  setEditableCell(null);
                  setRowToEdit(null);
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      )}
      <div className="mx-5">
        <Accordion className="my-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Columns and Headers</Accordion.Header>
            <Accordion.Body>
              <Accordion className="my-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Vendor Invoice Columns Data
                  </Accordion.Header>
                  <Accordion.Body>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <div className="d-flex justify-content-center border-white rounded shadow-sm p-3">
                          <div className="col-md-12">
                            <div className="text-center">
                              All Invoice Columns
                            </div>
                            <hr className="featurette-divider" />

                            <div className="d-flex justify-content-center">
                              {Array.from({ length: 4 }, (_, columnIndex) => (
                                <div key={columnIndex} className="col-md-3">
                                  {vendorDetails?.metadata?.all_invoice_column_names
                                    .filter(
                                      (_, index) => index % 4 === columnIndex
                                    )
                                    .map((column, index) => (
                                      <div
                                        key={index}
                                        className="d-flex mx-5 border-bottom py-2"
                                        style={{
                                          textTransform: "capitalize",
                                          textAlign: "left",
                                        }}
                                      >
                                        <input
                                          className="form-check-input mx-2"
                                          type="checkbox"
                                          checked={vendorDetails.required_invoice_columns_for_item_master.includes(
                                            column
                                          )}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              const updatedColumns = [
                                                ...vendorDetails.required_invoice_columns_for_item_master,
                                                column,
                                              ];
                                              setvendorDetails({
                                                ...vendorDetails,
                                                required_invoice_columns_for_item_master:
                                                  updatedColumns,
                                              });
                                            } else {
                                              const updatedColumns =
                                                vendorDetails.required_invoice_columns_for_item_master.filter(
                                                  (col) => col !== column
                                                );
                                              setvendorDetails({
                                                ...vendorDetails,
                                                required_invoice_columns_for_item_master:
                                                  updatedColumns,
                                              });
                                            }
                                          }}
                                        />
                                        {column}
                                      </div>
                                    ))}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <div className="border-white rounded shadow-sm p-3">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Header Display Name</th>
                          <th>Actual Header Name</th>
                          <th>Actual Header Position</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vendorDetails &&
                          Array.isArray(
                            vendorDetails?.invoice_header_names_mapping
                          ) &&
                          vendorDetails?.invoice_header_names_mapping?.map(
                            (header, index) => (
                              <tr key={index}>
                                <td>
                                  <Dropdown
                                    variant="light"
                                    onSelect={(value) => {
                                      const updatedHeaders = [
                                        ...vendorDetails.invoice_header_names_mapping,
                                      ];
                                      updatedHeaders[index].name = value;
                                      setvendorDetails({
                                        ...vendorDetails,
                                        invoice_header_names_mapping:
                                          updatedHeaders,
                                      });
                                    }}
                                  >
                                    <Dropdown.Toggle
                                      variant="light"
                                      style={{
                                        textTransform: "capitalize",
                                        width: "100%",
                                        textAlign: "left",
                                      }}
                                    >
                                      {header?.name !== ""
                                        ? header.name
                                        : "Select"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {vendorDetails?.metadata?.all_invoice_column_names?.map(
                                        (column, index) => (
                                          <Dropdown.Item
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                            key={index}
                                            eventKey={column}
                                          >
                                            {column}
                                          </Dropdown.Item>
                                        )
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={header.actual_header_name}
                                    onChange={(e) => {
                                      const updatedHeaders = [
                                        ...vendorDetails.invoice_header_names_mapping,
                                      ];
                                      updatedHeaders[index].actual_header_name =
                                        e.target.value;
                                      setvendorDetails({
                                        ...vendorDetails,
                                        invoice_header_names_mapping:
                                          updatedHeaders,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    min={1}
                                    type="number"
                                    value={header.actual_header_position}
                                    onChange={(e) => {
                                      const updatedHeaders = [
                                        ...vendorDetails.invoice_header_names_mapping,
                                      ];
                                      updatedHeaders[
                                        index
                                      ].actual_header_position = parseInt(
                                        e.target.value
                                      );
                                      setvendorDetails({
                                        ...vendorDetails,
                                        invoice_header_names_mapping:
                                          updatedHeaders,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                      const updatedHeaders = [
                                        ...vendorDetails.invoice_header_names_mapping,
                                      ];
                                      updatedHeaders.splice(index, 1);
                                      setvendorDetails({
                                        ...vendorDetails,
                                        invoice_header_names_mapping:
                                          updatedHeaders,
                                      });
                                      toast.warning("Header deleted");
                                    }}
                                  >
                                    <Trash3Fill />
                                  </button>
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>

                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-sm btn-info w-75"
                        onClick={() => {
                          let updatedHeaders = [
                            ...vendorDetails.invoice_header_names_mapping,
                          ];
                          updatedHeaders.push({
                            name: "",
                            actual_header_name: "",
                            actual_header_position: "",
                          });
                          setvendorDetails({
                            ...vendorDetails,
                            invoice_header_names_mapping: updatedHeaders,
                          });
                          toast.info("New header added");
                        }}
                      >
                        <PlusCircleFill className="mx-2 mb-1" fill="white" />
                        Add New Header
                      </button>
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
              <div className="w-100 d-flex justify-content-end mt-2">
                <div
                  className="mb-3"
                  style={{
                    width: "10%",
                    alignContent: "end",
                    justifyContent: "end",
                  }}
                >
                  <button
                    className="btn btn-success"
                    style={{ width: "100%" }}
                    onClick={() => handleSaveVendorDetails(vendorDetails)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="mx-5">
        <Accordion className="my-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Submit an Issue</Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <IssueSubmitScreen
                    mutate={mutate}
                    uuid={itemList?.[0]?.item_uuid}
                    isPending={isPending}
                    isLoading={issuesDataLoading}
                    data={issuesData}
                    error={issuesDataError}
                  />
                </ListGroup.Item>
              </ListGroup>
              <div className="w-100 d-flex justify-content-end mt-2">
                <div
                  className="mb-3"
                  style={{
                    width: "10%",
                    alignContent: "end",
                    justifyContent: "end",
                  }}
                >
                  <button
                    className="btn btn-success"
                    style={{ width: "100%" }}
                    onClick={() => handleSaveVendorDetails(vendorDetails)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Similar Items
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Searched Item Code: {masterItem?.item_code}{" "}
          </p>
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Searched Item Description: {masterItem?.item_description}{" "}
          </p>
          {similarItems?.length > 0 ? (
            <>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      className="text-center"
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      Category
                    </th>
                    <th
                      className="text-center"
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      Item Code
                    </th>
                    <th
                      className="text-center"
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      Item Description
                    </th>
                    <th
                      className="text-center"
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      Select
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {similarItems?.map(
                    ({ item_code, category, item_description, item_uuid }) => {
                      return (
                        <tr>
                          <td
                            className="text-center"
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            {category}
                          </td>
                          <td
                            className="text-center"
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            {item_code}
                          </td>
                          <td
                            className="text-center"
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            {item_description}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                            className="text-center"
                          >
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              checked={selectedItems.includes(item_uuid)}
                              onChange={(e) => {
                                //
                                if (e.target.checked) {
                                  setSelectedItems((prev) => [
                                    ...prev,
                                    item_uuid,
                                  ]);
                                }
                                if (!e.target.checked) {
                                  setSelectedItems(
                                    selectedItems.filter(
                                      (item) => item != item_uuid
                                    )
                                  );
                                }
                              }}
                            />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <p>No Similar Item Available</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center ">
          <Button
            type="submit"
            form="add_issue_form"
            variant="success"
            onClick={() => {
              mergeHanler();
            }}
            className="mx-2 px-5 align-items-center text-center"
          >
            Merge
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
