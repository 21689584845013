import { Button, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useGlobalFilterActions } from "store/globalFIlter";
import runDeduplication from "./deduplication";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useAppStore from "store/global";
import { ShareFill } from "react-bootstrap-icons";
import useUpdateSearchParams from "hooks/useUpdateSearchParams";
import Comments from "components/Comments/Comments";
import { ChatDotsFill, JournalCheck } from "react-bootstrap-icons";
import Notes from "components/Comments/Notes";
export const InvoiceHeader = ({ children, showButtons = true }) => {
  const updateSearchParams = useUpdateSearchParams();
  const [show, setShow] = useState(false);

  const [showToolTip, setShowToolTip] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const { setVerified } = useGlobalFilterActions();
  const [deduplicationLoading, setDeduplicationLoading] = useState(false);
  const { vendorName, invoiceUUID, pdfSource, vendorId } = useAppStore();
  const location = useLocation();
  const handleDeduplication = () => {
    setDeduplicationLoading(true);
    runDeduplication()
      .then((res) => {
        toast.success(res.message);
        setDeduplicationLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setDeduplicationLoading(false);
      });
  };
  const tooltip = <Tooltip id="tooltip">View Document Notes</Tooltip>;
  const tooltip2 = <Tooltip id="tooltip">View Vendor Notes</Tooltip>;
  return (
    <div
      className="mx-1"
      style={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        marginTop: "100px"
      }}
    >
      <div
        style={{
          backgroundColor: "#f0f0f0",
          padding: "10px",
          borderRadius: "5px",
          width: "100%",
          textAlign: "left"
        }}
        className="mx-4 d-flex justify-content-between"
      >
        {showButtons && (
          <>
            <ButtonGroup aria-label="Invoice Buttons">
              <Button
                variant="secondary"
                onClick={() => {
                  setVerified("both");
                  updateSearchParams({ filters: { human_verified: "both" } });
                }}
              >
                All
              </Button>
              <Button
                variant="secondary"
                className="text-black"
                style={{ backgroundColor: "rgb(253, 255, 208)" }}
                onClick={() => {
                  setVerified("false");
                  updateSearchParams({ filters: { human_verified: "false" } });
                }}
              >
                Raw Invoice
              </Button>
              <Button
                variant="warning"
                onClick={() => {
                  setVerified("true");
                  updateSearchParams({ filters: { human_verified: "true" } });
                }}
              >
                Verified Invoice
              </Button>
            </ButtonGroup>
            <ButtonGroup aria-label="Invoice Buttons" className="ml-auto">
              {location.pathname === "/details" ||
              location.pathname.startsWith("/invoice-details") ||
              location.pathname.startsWith("/details/both")
                ? pdfSource === "clickbacon" && (
                    <div style={{ position: "relative" }}>
                      <OverlayTrigger placement="left" overlay={tooltip}>
                        <Button
                          variant="success"
                          onMouseOver={() => {
                            setShowToolTip(true);
                          }}
                          onMouseLeave={() => setShowToolTip(false)}
                          className="mx-2"
                          onClick={() => setShow(true)}
                        >
                          <ChatDotsFill
                            height={20}
                            width={20}
                            fill="white"
                            className="cursor-pointer warning"
                          />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  )
                : null}
              {location.pathname === "/details" ||
              location.pathname.startsWith("/invoice-details") ||
              location.pathname.startsWith("/details/both") ? (
                <OverlayTrigger placement="left" overlay={tooltip2}>
                  <Button
                    variant="success"
                    className="mx-2"
                    onClick={() => setShowNotes(true)}
                    disabled={vendorId === null}
                  >
                    <JournalCheck
                      height={20}
                      width={20}
                      fill="white"
                      className="cursor-pointer warning"
                    />
                  </Button>
                </OverlayTrigger>
              ) : null}
              <Button variant="primary" onClick={handleDeduplication}>
                {deduplicationLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mx-1"
                  />
                ) : null}
                De-Duplication
              </Button>
              <Link
                className="mx-1"
                to="/vendor-consolidation"
                style={{ textDecoration: "none" }}
              >
                <Button variant="info">Vendor Consolidation</Button>
              </Link>
              {location.pathname === "/details" ||
              location.pathname.startsWith("/invoice-details") ||
              location.pathname.startsWith("/details/both") ? (
                <Link
                  className="mx-1"
                  to={`/rerun-invoices/${vendorName}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="warning">ReRun Invoices</Button>
                </Link>
              ) : null}
              {location.pathname === "/details" ||
              location.pathname.startsWith("/invoice") ||
              location.pathname.startsWith("/details/both") ? (
                <ShareFill
                  height={20}
                  width={20}
                  className="cursor-pointer warning mt-2 mx-2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/invoice-details/${invoiceUUID}`
                    );
                    toast.info("Link copied to clipboard");
                  }}
                />
              ) : null}
            </ButtonGroup>
          </>
        )}
        {children}
      </div>
      <ToastContainer />
      <Comments show={show} setShow={setShow} name="Comments" data={[]} />
      <Notes
        show={showNotes}
        setShow={setShowNotes}
        name="Vendor Notes"
        data={[]}
        vendorId={vendorId}
      />
    </div>
  );
};
