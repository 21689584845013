import { useQuery } from "@tanstack/react-query";
import { Spinner } from "components/common/Spinner";
import { InvoiceHeader } from "components/layout/InvoiceHeader";
import { NavHeader } from "components/layout/NavHeader";
import useUpdateParams from "hooks/useUpdateParams";
import { axiosInstance } from "lib/axios";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  Button,
  FormControl,
  Table
} from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

const Logs = () => {
  const [searchParams] = useSearchParams();
  const updateParams = useUpdateParams();
  let document_reference = searchParams.get("document_reference") || "";
  const useGetLogs = (document_id) => {
    return useQuery({
      queryKey: ["logs", document_id],
      queryFn: async () => {
        try {
          if (!document_id) {
            return;
          }
          const response = await axiosInstance.get(
            `/clickbacon_document/get_document_logs?document_reference=${document_id}`
          );
          return response?.data;
        } catch (error) {
          return error?.response?.data;
        }
      }
    });
  };

  const [reference, setReference] = useState(document_reference);

  const { data, isLoading } = useGetLogs(document_reference);

  return (
    <>
      <NavHeader />
      <InvoiceHeader showButtons={false}>
        <div className="d-flex align-items-center gap-2">
          <FormControl
            style={{ width: "400px" }}
            value={reference}
            onChange={(e) => setReference(e.target.value)}
            placeholder="Document reference"
          />
          <Button
            onClick={() => updateParams({ document_reference: reference })}
            style={{
              height: "38px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center"
            }}
          >
            Search
          </Button>
        </div>
      </InvoiceHeader>
      {isLoading && <Spinner />}
      {data && (
        <>
          {" "}
          <div className=" mt-4 d-flex  mx-4 ">
            <div
              style={{ width: "33.33%" }}
              className="border rounded-start-2 "
            >
              <p
                style={{ textAlign: "left", fontWeight: 600, fontSize: "14px" }}
                className="border-bottom p-2"
              >
                Document Balance Status
              </p>
              <div className="d-flex w-100 px-2">
                <div
                  style={{
                    width: "50%",

                    textAlign: "left",
                    fontSize: "13px"
                  }}
                >
                  <p>Document Uploaded Timestamp</p>
                  <p>Human Verified</p>
                  <p>Rejected</p>
                  <p>Rejection Reason</p>
                  <p>Timestamp</p>
                </div>
                <div
                  style={{
                    width: "50%",

                    textAlign: "left",
                    fontSize: "13px"
                  }}
                >
                  <p>
                    {
                      data?.document_status?.document_balance_status?.document_uploaded_timestamp?.split(
                        "."
                      )?.[0]
                    }
                  </p>
                  <p>
                    {data?.document_status?.document_balance_status
                      ?.human_verified
                      ? "Yes"
                      : "No"}
                  </p>
                  <p>
                    {data?.document_status?.document_balance_status?.rejected
                      ? "Yes"
                      : "No"}
                  </p>
                  <p>
                    {data?.document_status?.document_balance_status
                      ?.rejection_reaso || "-"}
                  </p>
                  <p>
                    {
                      data?.document_status?.document_balance_status?.timestamp?.split(
                        "."
                      )?.[0]
                    }
                  </p>
                </div>
              </div>
            </div>
            <div style={{ width: "33.33%" }} className="border  ">
              <p
                style={{ textAlign: "left", fontWeight: 600, fontSize: "14px" }}
                className="border-bottom p-2"
              >
                Document clickBACON Approval Status
              </p>
              <div className="d-flex w-100 px-2">
                <div
                  style={{
                    width: "50%",

                    textAlign: "left",
                    fontSize: "13px"
                  }}
                >
                  <p>Approved</p>
                  <p>Rejected</p>
                  <p>Rejection Reason</p>
                  <p>Rejection Timestamp</p>
                </div>
                <div
                  style={{
                    width: "50%",

                    textAlign: "left",
                    fontSize: "13px"
                  }}
                >
                  <p>
                    {data?.document_status?.document_clickbacon_approval_status
                      ?.approved
                      ? "Yes"
                      : "No"}
                  </p>
                  <p>
                    {data?.document_status?.document_clickbacon_approval_status
                      ?.rejected
                      ? "Yes"
                      : "No"}
                  </p>
                  <p>
                    {data?.document_status?.document_clickbacon_approval_status
                      ?.rejection_reason || "-"}
                  </p>
                  <p>
                    {data?.document_status?.document_clickbacon_approval_status?.rejection_timestamp?.split(
                      "."
                    )?.[0] || "-"}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ width: "33.33%" }} className="border rounded-end-2 ">
              <p
                style={{ textAlign: "left", fontWeight: 600, fontSize: "14px" }}
                className="border-bottom p-2"
              >
                Document clickBACON Sync Status
              </p>
              <div className="d-flex w-100 px-2">
                <div
                  style={{
                    width: "50%",

                    textAlign: "left",
                    fontSize: "13px"
                  }}
                >
                  <p>Synced</p>
                  <p>Rejected</p>
                  <p>Rejection Reason</p>
                  <p>Rejection Timestamp</p>
                </div>
                <div
                  style={{
                    width: "50%",

                    textAlign: "left",
                    fontSize: "13px"
                  }}
                >
                  <p>
                    {data?.document_status?.document_clickbacon_sync_status
                      ?.synced
                      ? "Yes"
                      : "No"}
                  </p>
                  <p>
                    {data?.document_status?.document_clickbacon_sync_status
                      ?.rejected
                      ? "Yes"
                      : "No"}
                  </p>
                  <p>
                    {data?.document_status?.document_clickbacon_sync_status
                      ?.rejection_reason || "-"}
                  </p>
                  <p>
                    {data?.document_status?.document_clickbacon_sync_status?.rejection_timestamp?.split(
                      "."
                    )?.[0] || "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-4 mt-4 ">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>clickBACON Events</Accordion.Header>
                <Accordion.Body>
                  <Table bordered striped size="sm">
                    <tr>
                      <th>Failed</th>
                      <th>Failing Reason</th>
                      <th>Log Type</th>
                      <th>Metadata</th>

                      <th>Timestamp</th>
                    </tr>
                    <tbody>
                      {data?.document_logs?.clickbacon_events?.map(
                        (
                          {
                            failed,
                            failed_reason,
                            log_type,
                            metadata,
                            timestamp
                          },
                          i
                        ) => {
                          return (
                            <tr key={i}>
                              <td>{failed ? "Yes" : "No"}</td>
                              <td>
                                {Object?.keys(failed_reason)?.map((k) => {
                                  return (
                                    <p key={k}>
                                      <span className="bold">{k}</span>:-{" "}
                                      {failed_reason[k]}
                                    </p>
                                  );
                                })}
                              </td>
                              <td>{log_type}</td>
                              <td>
                                {Object?.keys(metadata)?.map((k) => {
                                  return (
                                    <p key={k}>
                                      <span className="bold">{k}</span>:-{" "}
                                      {metadata[k]}
                                    </p>
                                  );
                                })}
                              </td>

                              <td>{timestamp?.split(".")?.[0]}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="0" className="mt-4">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Failed Events</Accordion.Header>
                <Accordion.Body>
                  <Table bordered striped size="sm">
                    <tr>
                      <th>Failed</th>
                      <th>Failing Reason</th>
                      <th>Log Type</th>
                      <th>Metadata</th>
                      <th>clickBACON Event</th>
                      <th>Invoice Intelligence Events</th>
                      <th>Timestamp</th>
                    </tr>
                    <tbody>
                      {data?.document_logs?.failed_events?.map(
                        (
                          {
                            failed,
                            failed_reason,
                            log_type,
                            metadata,
                            clickbacon_event,
                            invoice_intelligence_event,
                            timestamp
                          },
                          i
                        ) => {
                          return (
                            <tr key={i}>
                              <td>{failed ? "Yes" : "No"}</td>
                              <td>
                                {Object?.keys(failed_reason)?.map((k) => {
                                  return (
                                    <p key={k}>
                                      <span className="bold">{k}</span>:-{" "}
                                      {failed_reason[k]}
                                    </p>
                                  );
                                })}
                              </td>
                              <td>{log_type}</td>
                              <td>
                                {Object?.keys(metadata)?.map((k) => {
                                  return (
                                    <p key={k}>
                                      <span className="bold">{k}</span>:-{" "}
                                      {metadata[k]}
                                    </p>
                                  );
                                })}
                              </td>
                              <td>{clickbacon_event ? "Yes" : "No"}</td>
                              <td>
                                {invoice_intelligence_event ? "Yes" : "No"}
                              </td>
                              <td>{timestamp?.split(".")?.[0]}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="0" className="mt-4">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Logs</Accordion.Header>
                <Accordion.Body>
                  <Table bordered striped size="sm">
                    <tr>
                      <th>Failed</th>
                      <th>Failing Reason</th>
                      <th>Log Type</th>
                      <th>Metadata</th>
                      <th>clickBACON Event</th>
                      <th>Invoice Intelligence Events</th>
                      <th>Timestamp</th>
                    </tr>
                    <tbody>
                      {data?.document_logs?.logs?.map(
                        (
                          {
                            failed,
                            failed_reason,
                            log_type,
                            metadata,
                            clickbacon_event,
                            invoice_intelligence_event,
                            timestamp
                          },
                          i
                        ) => {
                          return (
                            <tr key={i}>
                              <td>{failed ? "Yes" : "No"}</td>
                              <td>
                                {Object?.keys(failed_reason)?.map((k) => {
                                  return (
                                    <p key={k}>
                                      <span className="bold">{k}</span>:-{" "}
                                      {failed_reason[k]}
                                    </p>
                                  );
                                })}
                              </td>
                              <td>{log_type}</td>
                              <td>
                                {Object?.keys(metadata)?.map((k) => {
                                  return (
                                    <p key={k}>
                                      <span className="bold">{k}</span>:-{" "}
                                      {metadata[k]}
                                    </p>
                                  );
                                })}
                              </td>
                              <td>{clickbacon_event ? "Yes" : "No"}</td>
                              <td>
                                {invoice_intelligence_event ? "Yes" : "No"}
                              </td>
                              <td>{timestamp?.split(".")?.[0]}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="0" className="mt-4">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Invoice Intelligence Events</Accordion.Header>
                <Accordion.Body>
                  <Table bordered striped size="sm">
                    <tr>
                      <th>Failed</th>
                      <th>Failing Reason</th>
                      <th>Log Type</th>
                      <th>Metadata</th>

                      <th>Timestamp</th>
                    </tr>
                    <tbody>
                      {data?.document_logs?.invoice_intelligence_events?.map(
                        (
                          {
                            failed,
                            failed_reason,
                            log_type,
                            metadata,
                            timestamp
                          },
                          i
                        ) => {
                          return (
                            <tr key={i}>
                              <td>{failed ? "Yes" : "No"}</td>
                              <td>
                                {Object?.keys(failed_reason)?.map((k) => {
                                  return (
                                    <p key={k}>
                                      <span className="bold">{k}</span>:-{" "}
                                      {failed_reason[k]}
                                    </p>
                                  );
                                })}
                              </td>
                              <td>{log_type}</td>
                              <td>
                                {Object?.keys(metadata)?.map((k) => {
                                  return (
                                    <p key={k}>
                                      {" "}
                                      <span className="bold">{k}</span>:-{" "}
                                      {metadata[k]}
                                    </p>
                                  );
                                })}
                              </td>

                              <td>{timestamp?.split(".")?.[0]}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </>
      )}
    </>
  );
};

export default Logs;
