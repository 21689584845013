import { queryClient } from "lib/react-query";
import { useState } from "react";
import {
  Accordion,
  Button,
  Dropdown,
  Form,
  FormControl,
  ListGroup,
  Modal,
  Table
} from "react-bootstrap";
import { PlusCircleFill, SaveFill, Trash3Fill } from "react-bootstrap-icons";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  useDeleteVendor,
  useGetAdditionalData,
  useGetInvoiceHeaderMappingException,
  useUpdateInvoiceHeaderMappingException,
  useUpdateVendorDetails
} from "../api";
import { globalStoreV2 } from "store/globalStoreV2";

const headers = [
  { label: "Vendor Id", value: "vendor_id" },
  { label: "Vendor Name", value: "vendor_name" },
  { label: "Human Verified", value: "human_verified" },
  { label: "Auto Approve Invoices", value: "auto_approve_invoices" },
  { label: "Document Count", value: "document_count" },
  { label: "Total Items", value: "item_count" },
  { label: "Branch Count", value: "branch_count" },
  { label: "Verified Item Count", value: "verified_item_count" },
  { label: "Verified Branch Count", value: "verified_branch_count" },
  { label: "Vendor Category", value: "vendor_category" },
  { label: "Account Category", value: "vendor_account_category" },
  { label: "Vendor Name Synonyms", value: "vendor_name_synonyms" },
  { label: "Vendor Document Type", value: "vendor_document_type" }
];

const VendorDetailsTable = ({ data, handleSaveVendorDetails }) => {
  const navigate = useNavigate();
  const [vendorCategorySearchTerm, setVendorCategorySearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { mutate: deleteVendor } = useDeleteVendor();
const {setActual_vendor_name}=  globalStoreV2()
  const [headerDisplayNameSearchTerm, setHeaderDisplayNameSearchTerm] =
    useState("");
  const [headerRawName, setHeaderRawName] = useState("");
  const { vendorID: vendor_id } = useParams();
  const { mutate: updateVendorDetails } = useUpdateVendorDetails();
  const setData = (key, val) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    let { data: myData } = copyObj;
    myData[key] = val;
    queryClient.setQueryData(["vendor-details", vendor_id], copyObj);
  };

  const { data: additionalData, isLoading: loadingAdditionalData } =
    useGetAdditionalData();
  const { mutate: updateExceptions, isPending: updatingExceptions } =
    useUpdateInvoiceHeaderMappingException();
  const filteredColumns =
    additionalData?.data?.processed_table_header_candidates?.filter(
      (item) => item?.is_required_for_item_master
    );
  let headerDisplayNameOptions =
    additionalData?.data?.processed_table_header_candidates
      ?.map(
        ({ is_default_in_item_master, is_required_for_item_master, ...rest }) =>
          rest
      )
      ?.map(({ column_name }) => column_name);

  const handleCheckboxChange = (column_name, value) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    let { required_invoice_columns_for_item_master } = copyObj?.data;

    if (!required_invoice_columns_for_item_master) {
      required_invoice_columns_for_item_master = [];
    }
    const columnNameLower = column_name.toLowerCase();

    if (value === false) {
      //    Remove the column name if it exists
      required_invoice_columns_for_item_master =
        required_invoice_columns_for_item_master.filter(
          (item) => item.toLowerCase() !== columnNameLower
        );
    } else {
      //   Add the column name if it doesn't exist
      if (!required_invoice_columns_for_item_master.includes(columnNameLower)) {
        required_invoice_columns_for_item_master.push(columnNameLower);
      }
    }

    // Update the copy of the data
    copyObj.data.required_invoice_columns_for_item_master =
      required_invoice_columns_for_item_master;

    // Update the query data with the modified copy
    queryClient.setQueryData(["vendor-details", vendor_id], copyObj);
  };

  const { data: headerMappingExceptions, isLoading: loadingExceptions } =
    useGetInvoiceHeaderMappingException(vendor_id);

  const handleRemoveItem = (item, index) => {
    let copyObj = JSON.parse(JSON.stringify(headerMappingExceptions));

    let filtered =
      headerMappingExceptions?.data?.invoice_header_exceptions.filter(
        (it) => it.header_display_name?.toLowerCase() !== item?.toLowerCase()
      );
    copyObj.data.invoice_header_exceptions = filtered;

    queryClient.setQueryData(
      ["invoice-header-mapping-exceptions", vendor_id],
      copyObj
    );
  };

  const addNewHeader = () => {
    let copyObj = JSON.parse(JSON.stringify(headerMappingExceptions));
    let { invoice_header_exceptions } = copyObj?.data;
    invoice_header_exceptions.push({
      header_display_name: null,
      header_raw_names: []
    });

    queryClient.setQueryData(
      ["invoice-header-mapping-exceptions", vendor_id],
      copyObj
    );
  };
  const handleInputValueChange = (key, value, item) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    let { invoice_header_names_mapping } = copyObj?.data;
    invoice_header_names_mapping[item][key] = value;
    queryClient.setQueryData(["vendor-details", vendor_id], copyObj);
  };

  const handleHeaderDisplayNameSelect = (item, value, index) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    let { invoice_header_names_mapping } = copyObj?.data;

    if (item in invoice_header_names_mapping) {
      // Create a new object to maintain order
      const newMapping = {};
      // Copy existing keys to the new object
      Object?.keys(invoice_header_names_mapping).forEach((key) => {
        if (key === item) {
          newMapping[value] = invoice_header_names_mapping[key]; // Add the new key
        } else {
          newMapping[key] = invoice_header_names_mapping[key]; // Copy other keys as is
        }
      });
      copyObj.data.invoice_header_names_mapping = newMapping;
    }

    queryClient.setQueryData(["vendor-details", vendor_id], copyObj);
  };
  const deleteRow = (item) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    let { invoice_header_names_mapping } = copyObj?.data;

    if (item in invoice_header_names_mapping) {
      delete invoice_header_names_mapping[item];
    }
    queryClient.setQueryData(["vendor-details", vendor_id], copyObj);
  };
  return (
    <div
      className="mx-5"
      style={{
        marginTop: "50px"
      }}
    >
      <ToastContainer />
      <Table striped bordered hover>
        <thead onClick={() => console.log(data)}>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {headers?.map(({ label, value }) => {
            return (
              <tr key={label} style={{}}>
                <td style={{ width: "50%", alignContent: "center" }}>
                  {label}
                </td>
                <td>
                  {value == "vendor_name" ? (
                    <FormControl
                      type="text"
                      value={data?.data?.[`${value}`]}
                      className="form-control mx-auto "
                      placeholder="Vendor Name"
                      onInput={(e) => {
                        setData("vendor_name", e.target.value);
                      }}
                      style={{
                        width: "100%"
                      }}
                    />
                  ) : value == "human_verified" ? (
                    <>
                      {" "}
                      <Form.Check
                        checked={data?.data?.[`human_verified`]}
                        type="switch"
                        onChange={(e) => {
                          setData("human_verified", e.target.checked);
                        }}
                      />
                    </>
                  ) : value == "auto_approve_invoices" ? (
                    <>
                      {" "}
                      <Form.Check
                        checked={data?.data?.[`auto_approve_invoices`]}
                        type="switch"
                        onChange={(e) => {
                          setData("auto_approve_invoices", e.target.checked);
                        }}
                      />
                    </>
                  ) : value == "vendor_category" ? (
                    <>
                      <Dropdown onChange={(e) => e.target.value}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {data?.data?.["vendor_category"] == null
                            ? "None"
                            : data?.data?.["vendor_category"]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {[
                            "Normal Invoice",
                            "Liquor Invoice",
                            "Summary Invoice",
                            "None"
                          ]?.map((item) => (
                            <Dropdown.Item
                              eventKey={item}
                              onClick={() => {
                                setData(
                                  "vendor_category",
                                  item == "None" ? null : item
                                );
                              }}
                            >
                              {item}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : value == "vendor_document_type" ? (
                    <>
                      <Dropdown onChange={(e) => e.target.value}>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          style={{ textTransform: "capitalize" }}
                        >
                          {data?.data?.["vendor_document_type"] == null
                            ? "None"
                            : data?.data?.["vendor_document_type"]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {["bill", "expense", "None"]?.map((item) => (
                            <Dropdown.Item
                              eventKey={item}
                              style={{ textTransform: "capitalize" }}
                              onClick={() => {
                                setData(
                                  "vendor_document_type",
                                  item == "None" ? null : item
                                );
                              }}
                            >
                              {item}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : value == "vendor_account_category" ? (
                    <>
                      <Dropdown onChange={(e) => e.target.value}>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          style={{ textTransform: "capitalize" }}
                        >
                          {data?.data?.["vendor_account_category"]?.name == null
                            ? "None"
                            : data?.data?.["vendor_account_category"]?.name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          style={{
                            maxHeight: "200px",
                            overflow: "auto",
                            paddingLeft: 4,
                            paddingRight: 4
                          }}
                        >
                          <FormControl
                            placeholder="Search"
                            style={{ position: "sticky", top: -8 }}
                            value={vendorCategorySearchTerm}
                            onChange={(e) => {
                              setVendorCategorySearchTerm(e.target.value);
                            }}
                          />
                          {additionalData?.data?.category_choices
                            ?.filter((item) =>
                              item?.name
                                ?.toLowerCase()
                                ?.includes(vendorCategorySearchTerm)
                            )
                            ?.map((item) => (
                              <Dropdown.Item
                                eventKey={item?.category_id}
                                style={{ textTransform: "capitalize" }}
                                onClick={() => {
                                  setData("vendor_account_category", item);
                                }}
                              >
                                {item?.name}
                              </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : value == "vendor_name_synonyms" ? (
                    <>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {data?.data?.["vendor_name_synonyms"]?.[0]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {data?.data?.["vendor_name_synonyms"]?.map((item) => {
                            return (
                              <Dropdown.Item
                                key={item}
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  justifyContent: "space-between",
                                  width: "400px",
                                  background: "transparent"
                                }}
                              >
                                {item}
                                <button
                                  onClick={() => {
                                    setData(
                                      "vendor_name_synonyms",
                                      data?.data?.[
                                        "vendor_name_synonyms"
                                      ]?.filter((it) => it !== item)
                                    );
                                  }}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: 5,
                                    zIndex: 10000
                                  }}
                                >
                                  Remove
                                </button>
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : typeof data?.data?.[`${value}`] == "object" ? (
                    <></>
                  ) : (
                    <FormControl
                      type="text"
                      value={data?.data?.[`${value}`]}
                      className={`form-control mx-auto ${
                        [
                          "verified_branch_count",
                          "verified_item_count"
                        ].includes(value) && "border-success"
                      }`}
                      placeholder="Vendor Name"
                      onInput={(e) => {
                        // setData("vendor_name", e.target.value);
                      }}
                      disabled
                      style={{
                        width: "100%"
                      }}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* First Accordion */}
      <Accordion defaultActiveKey="0" style={{ marginBottom: 20 }}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Invoice Header Column Data</Accordion.Header>
          <Accordion.Body>
            <p
              className="w-100"
              style={{
                borderBottom: "1px solid #7D7C7C",
                marginTop: 15,
                paddingBottom: 10,
                display: "flex",
                justifyContent: "center"
              }}
            >
              All Invoice Columns
            </p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(5, 1fr)",
                gap: "10px",
                marginBottom: 10
              }}
            >
              {filteredColumns?.map((item) => (
                <div
                  key={item?.column_name}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 40,
                    padding: "0 10px"
                  }}
                >
                  <input
                    type="checkbox"
                    checked={data?.data?.required_invoice_columns_for_item_master?.some(
                      (it) =>
                        it?.toLowerCase() === item?.column_name?.toLowerCase()
                    )}
                    onChange={(e) => {
                      handleCheckboxChange(item?.column_name, e.target.checked);
                    }}
                  />
                  <p style={{ paddingLeft: 5, margin: 0 }}>
                    {" "}
                    {item?.column_name}
                  </p>
                </div>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Second Accordion */}

      <Accordion defaultActiveKey="0" style={{ marginBottom: 20 }}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Invoice Header Mapping</Accordion.Header>
          <Accordion.Body>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <div className="border-white rounded shadow-sm p-3">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Header Display Name</th>
                        <th>Actual Header Name</th>
                        <th>Actual Header Position</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.invoice_header_names_mapping &&
                        Object?.keys(
                          data?.data?.invoice_header_names_mapping
                        )?.map((item) => {
                          return (
                            <tr key={item}>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "center"
                                }}
                              >
                                <Dropdown
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    width: "80%"
                                  }}
                                >
                                  <Dropdown.Toggle
                                    style={{
                                      width: "100%",
                                      alignContent: "start",
                                      justifyContent: "start",
                                      alignItems: "start",
                                      color: "black",
                                      border: "1px solid #E9EFEC",
                                      background: "white",
                                      display: "flex",
                                      alignItems: "center", // Center items vertically
                                      justifyContent: "flex-start", //,
                                      textTransform: "capitalize"
                                    }}
                                  >
                                    {item == null
                                      ? "Select Header Display Name"
                                      : item}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu
                                    style={{
                                      maxHeight: "200px",
                                      overflow: "auto"
                                    }}
                                  >
                                    <FormControl
                                      value={headerDisplayNameSearchTerm}
                                      placeholder="Search"
                                      style={{ position: "sticky", top: -7 }}
                                      onChange={(e) =>
                                        setHeaderDisplayNameSearchTerm(
                                          e.target.value
                                        )
                                      }
                                    />
                                    {headerDisplayNameOptions
                                      ?.filter((itm) =>
                                        itm
                                          ?.toLowerCase()
                                          .includes(
                                            headerDisplayNameSearchTerm?.toLowerCase()
                                          )
                                      )
                                      ?.map((it, ind) => {
                                        return (
                                          <Dropdown.Item
                                            key={ind}
                                            onClick={() =>
                                              handleHeaderDisplayNameSelect(
                                                item,
                                                it
                                              )
                                            }
                                          >
                                            {it}
                                          </Dropdown.Item>
                                        );
                                      })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>

                              <td>
                                <FormControl
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      "actual_header_name",
                                      e.target.value,
                                      item
                                    )
                                  }
                                  value={
                                    data.data.invoice_header_names_mapping[item]
                                      ?.actual_header_name
                                  }
                                />
                              </td>
                              <td>
                                <FormControl
                                  type="number"
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      "actual_header_position",
                                      e.target.value,
                                      item
                                    )
                                  }
                                  value={
                                    data.data.invoice_header_names_mapping[item]
                                      ?.actual_header_position
                                  }
                                />
                              </td>
                              <td style={{ justifyContent: "center" }}>
                                <button
                                  onClick={() => deleteRow(item)}
                                  type=""
                                  style={{
                                    border: "none",
                                    background: "red",
                                    borderRadius: 5
                                  }}
                                >
                                  <Trash3Fill
                                    color="white"
                                    style={{ margin: 6, marginBottom: 8 }}
                                  />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Third Accordion */}
      <Accordion defaultActiveKey="0" style={{ marginBottom: 20 }}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Invoice Header Mapping - Exceptions
          </Accordion.Header>
          <Accordion.Body>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <div className="border-white rounded shadow-sm p-3">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Header Display Name</th>
                        <th>Header Raw Name</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {headerMappingExceptions?.data?.invoice_header_exceptions?.map(
                        (
                          { header_raw_names, header_display_name },
                          mainIndex
                        ) => {
                          return (
                            <tr>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "center"
                                }}
                              >
                                <Dropdown
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    width: "80%"
                                  }}
                                >
                                  <Dropdown.Toggle
                                    // onClick={()=>  setHeaderRawName("")}
                                    style={{
                                      width: "100%",
                                      alignContent: "start",
                                      justifyContent: "start",
                                      alignItems: "start",
                                      color: "black",
                                      border: "1px solid #E9EFEC",
                                      background: "white",
                                      display: "flex",
                                      alignItems: "center", // Center items vertically
                                      justifyContent: "flex-start" //
                                    }}
                                  >
                                    {header_display_name == null
                                      ? "Select Header Display Name"
                                      : header_display_name}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu
                                    style={{
                                      maxHeight: "200px",
                                      overflow: "auto"
                                    }}
                                  >
                                    <FormControl
                                      value={headerDisplayNameSearchTerm}
                                      placeholder="Search"
                                      style={{ position: "sticky", top: -7 }}
                                      onChange={(e) =>
                                        setHeaderDisplayNameSearchTerm(
                                          e.target.value
                                        )
                                      }
                                    />
                                    {headerDisplayNameOptions
                                      ?.filter((itm) =>
                                        itm
                                          ?.toLowerCase()
                                          .includes(
                                            headerDisplayNameSearchTerm?.toLowerCase()
                                          )
                                      )
                                      ?.map((it, ind) => {
                                        return (
                                          <Dropdown.Item
                                            key={ind}
                                            onClick={() => {
                                              let copyObj = JSON.parse(
                                                JSON.stringify(
                                                  headerMappingExceptions
                                                )
                                              );
                                              let {
                                                invoice_header_exceptions
                                              } = copyObj?.data;

                                              invoice_header_exceptions[
                                                mainIndex
                                              ]["header_display_name"] = it;
                                              queryClient.setQueryData(
                                                [
                                                  "invoice-header-mapping-exceptions",
                                                  vendor_id
                                                ],
                                                copyObj
                                              );
                                              setHeaderDisplayNameSearchTerm(
                                                ""
                                              );
                                            }}
                                          >
                                            {it}
                                          </Dropdown.Item>
                                        );
                                      })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>

                              <td
                                style={{
                                  justifyContent: "center",
                                  width: "auto"
                                }}
                              >
                                <Dropdown
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    width: "100%"
                                  }}
                                >
                                  <Dropdown.Toggle
                                    // onClick={()=>setHeaderRawName("")}
                                    style={{
                                      width: "70%",
                                      display: "flex",
                                      alignItems: "center", // Center items vertically
                                      justifyContent: "flex-start", // Align items to the start horizontally
                                      color: "black",
                                      border: "1px solid #E9EFEC",
                                      background: "white"
                                    }}
                                  >
                                    {header_raw_names?.length == 0 && "Select"}
                                    {header_raw_names?.map((it, index) => (
                                      <p
                                        key={it}
                                        style={{
                                          textTransform: "capitalize",
                                          margin: 0
                                        }}
                                      >
                                        {" "}
                                        {/* Remove margin for better centering */}{" "}
                                        {it}{" "}
                                        {index !==
                                        header_raw_names?.length - 1 ? (
                                          <> , </>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                    ))}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      style={{ background: "white" }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center"
                                        }}
                                      >
                                        <FormControl
                                          type="text"
                                          value={headerRawName}
                                          onChange={(e) =>
                                            setHeaderRawName(e.target.value)
                                          }
                                        />
                                        <Button
                                          className="m-1"
                                          onClick={() => {
                                            let copyObj = JSON.parse(
                                              JSON.stringify(
                                                headerMappingExceptions
                                              )
                                            );
                                            const {
                                              invoice_header_exceptions
                                            } = copyObj.data;
                                            invoice_header_exceptions[
                                              mainIndex
                                            ]["header_raw_names"] = [
                                              ...invoice_header_exceptions[
                                                mainIndex
                                              ]["header_raw_names"],
                                              headerRawName
                                            ];
                                            queryClient.setQueryData(
                                              [
                                                "invoice-header-mapping-exceptions",
                                                vendor_id
                                              ],
                                              copyObj
                                            );
                                            setHeaderRawName("");
                                          }}
                                        >
                                          Add
                                        </Button>
                                      </div>
                                    </Dropdown.Item>

                                    {header_raw_names?.map((it, i) => {
                                      return (
                                        <Dropdown.Item
                                          key={i}
                                          style={{
                                            textTransform: "capitalize"
                                          }}
                                        >
                                          {it}
                                        </Dropdown.Item>
                                      );
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td style={{ justifyContent: "center" }}>
                                <button
                                  onClick={() => {
                                    handleRemoveItem(
                                      header_display_name,
                                      mainIndex
                                    );
                                  }}
                                  type=""
                                  style={{
                                    border: "none",
                                    background: "red",
                                    borderRadius: 5
                                  }}
                                >
                                  <Trash3Fill
                                    color="white"
                                    style={{ margin: 6, marginBottom: 8 }}
                                  />
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-sm btn-info w-50 mx-2"
                      onClick={() => addNewHeader()}
                    >
                      <PlusCircleFill className="mx-2 mb-1" fill="white" />
                      Add New Header
                    </button>
                    <button
                      className="btn btn-sm btn-primary w-50 mx-2"
                      onClick={() => {
                        setLoading(true);
                        updateExceptions(
                          {
                            vendor_id,
                            invoice_header_exceptions:
                              headerMappingExceptions?.data
                                ?.invoice_header_exceptions
                          },
                          {
                            onSettled: () => {
                              setLoading(false);
                            }
                          }
                        );
                      }}
                    >
                      <SaveFill className="mx-2 mb-1" fill="white" />
                      {loading ? "Saving...." : "Save"}
                    </button>
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className="mt-4 mx-5 mb-3 d-flex flex-wrap justify-content-between">
        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-success"
            style={{ width: "100%" }}
            disabled={updating}
            onClick={() => {
              setUpdating(true);
              updateVendorDetails(
                { vendor_id, data: data?.data },
                {
                  onSuccess: () => {
                    setUpdating(false);
                  },
                  onError: () => {
                    setUpdating(false);
                  }
                }
              );
            }}
          >
            {updating ? "Saving...." : "Save"}
          </button>
        </div>

        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-danger"
            style={{ width: "100%" }}
            onClick={() => setShowDeleteModal(true)}
            // disabled={deleting}
          >
            {/* {deleting ? "Deleting..." : "Delete"} */}
            Delete
          </button>
        </div>

        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-primary"
            style={{ width: "100%" }}
            onClick={() => {
              setActual_vendor_name(data?.data?.vendor_name)
              
              navigate(
                `/vendor-consolidation-v2/combine-vendors/${data?.data?.vendor_id}`
              );
            }}
          >
            Find Similar Vendors
          </button>
        </div>

        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-warning"
            style={{ width: "100%" }}
            onClick={() => {
              // handleButtonClick(data.vendor_name);
              // handleViewInvoice(data.vendor_name);
            }}
          >
            View Invoices
          </button>
        </div>

        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-info"
            style={{ width: "100%" }}
            onClick={() => {
              navigate(
                `/vendor-consolidation-v2/branches/${data?.data?.vendor_id}`
              );
            }}
          >
            View Branches
          </button>
        </div>

        {data?.vendor_category !== "Summary Invoice" && (
          <>
            <div className="mb-3" style={{ width: "48%" }}>
              <button
                className="btn btn-dark"
                style={{ width: "100%" }}
                onClick={() => {
                  navigate(
                    `/vendor-consolidation-v2/vendor-item-master/${data?.data?.vendor_id}`
                  );
                }}
              >
                View Items
              </button>
            </div>

            <div className="mb-3" style={{ width: "48%" }}>
              <button
                className="btn btn-dark"
                style={{ width: "100%" }}
                onClick={() => {
                  navigate(
                    `/fast-item-master-verification-v2/${data?.data?.vendor_id}?vendor_name=${data?.data?.vendor_name}`
                  );
                }}
              >
                Fast Item Verification
              </button>
            </div>
          </>
        )}
        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-danger"
            style={{ width: "100%" }}
            onClick={() => {
              // handleDisapproveAllItems(data.vendor_id);
            }}
            // disabled={isPending}
          >
            {/* {isPending ? "Disapproving ..." : "Disapprove All Items"} */}
            Disapprove
          </button>
        </div>
      </div>
      <Modal
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this vendor?
          <table className="table my-2 bordered responsive">
            <tbody>
              <tr>
                <td>Vendor Name</td>
                <td>{data?.data?.vendor_name}</td>
              </tr>
              <tr>
                <td>Vendor ID</td>
                <td>{data?.data?.vendor_id}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              setDeleting(true);
              deleteVendor(vendor_id, {
                onSuccess: () => {
                  setDeleting(false);
                },
                onError: () => {
                  setDeleting(false);
                }
              });
            }}
            disabled={deleting}
          >
            {deleting ? "Deleting..." : "Delete"}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VendorDetailsTable;
