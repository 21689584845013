import React, { useState } from "react";
import { Table, Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { PatchCheckFill } from "react-bootstrap-icons";
import { useCombineVendors } from "../api";
import { globalStoreV2 } from "store/globalStoreV2";

export default function VendorCombineTable({
  vendorData = [],
  handleCombineVendors,
  actualVendorName,
  setActualVendorName
}) {
  const { vendorID: vendor_id } = useParams();
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [selectedVendorNames, setSelectedVendorNames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [combining, setCombining] = useState(false);
  const {actual_vendor_name}=globalStoreV2()
  const { mutate: combineVendors } = useCombineVendors();
  const handleCheckboxChange = (vendorId, vendorName) => {
    setSelectedVendors((prevSelectedVendors) =>
      prevSelectedVendors.includes(vendorId)
        ? prevSelectedVendors.filter((id) => id !== vendorId)
        : [...prevSelectedVendors, vendorId]
    );

    setSelectedVendorNames((prevSelectedVendorNames) =>
      prevSelectedVendorNames.includes(vendorName)
        ? prevSelectedVendorNames.filter((name) => name !== vendorName)
        : [...prevSelectedVendorNames, vendorName]
    );
  };

  const handleCombineConfirmation = () => {
    setCombining(true);
    combineVendors(
      { vendor_id, vendors_to_combine: selectedVendors },
      {
        onSuccess: () => {
          setCombining(false);
          setShowModal(false);
          setSelectedVendors([]);
          setSelectedVendorNames([]);
        },
        onError: () => {
          setCombining(false);
          setShowModal(false);
        }
      }
    );
  };

  return (
    <div className="mx-5" style={{ marginTop: "50px" }}>
      <div className="d-flex justify-content-start align-items-center my-4 p-1">
        <label htmlFor="actualVendorName">Actual Vendor Name: </label>
        <input
          id="actualVendorName"
          name="actualVendorName"
          type="text"
          className="form-control mx-2"
          value={actual_vendor_name}
          onChange={(e) => {
            setActualVendorName(e.target.value);
          }}
          style={{
            width: "50%"
          }}
        />
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Vendor Name</th>
            <th>Document Count</th>
            <th>Matching Score</th>
            <th>Invoice</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          {vendorData?.data?.length > 0 &&
            vendorData?.data?.map(
              ({ vendor, matching_score, documents_count }) => {
                return (
                  <tr>
                    <td>
                      {vendor?.vendor_name}{" "}
                      {vendor?.human_verified && <PatchCheckFill fill="blue" />}
                    </td>
                    <td>{documents_count}</td>
                    <td>{matching_score}</td>
                    <td>
                      {" "}
                      <Link
                        target="_blank"
                        to={`/compare-invoices-v2/${vendor?.vendor_name}/${actual_vendor_name}`}
                      >
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          View
                        </button>
                      </Link>
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        disabled={vendor?.human_verified}
                        checked={selectedVendors.includes(vendor.vendor_id)}
                        onChange={() =>
                          handleCheckboxChange(
                            vendor.vendor_id,
                            vendor.vendor_name
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>
      </Table>

      <div className="mt-2">
        <Button
          variant="success"
          style={{ width: "50%" }}
          onClick={() => {
            if (selectedVendors.length > 0) {
              // Show the confirmation modal
              setShowModal(true);
            } else {
              toast.error("Please select a minimum of one vendor to combine.");
            }
          }}
        >
          {combining ? "Combining Vendors...." : "Combine Vendors"}
        </Button>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Vendor Combination</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Actual Vendor Name: <strong>{actualVendorName}</strong>
          </p>
          <p>
            Selected Vendor Names:{" "}
            {selectedVendorNames.map((name) => (
              <span key={name} className="badge bg-primary mx-2">
                {name}
              </span>
            ))}
          </p>
          <p>Are you sure you want to combine these vendors?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleCombineConfirmation}>
            {combining ? "Combining Vendors..." : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="mt-4 border border-2 rounded-lg mx-2 p-2">
        Selected Vendor Names:{" "}
        {selectedVendorNames.map((name) => (
          <span key={name} className="badge bg-primary mx-2">
            {name}
          </span>
        ))}
      </div>
    </div>
  );
}
