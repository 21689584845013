import React from 'react';

const Skeleton = () => {
  // Inline styles for the skeleton component
  const skeletonStyle = {
    width: '100%',            // Full width
    height: '20px',          // Height of the skeleton
    background: '#e0e0e0',   // Light gray background
    borderRadius: '4px',     // Rounded corners
    animation: 'pulse 1.5s infinite', // Pulsing animation
  };

  // Add a keyframe animation for pulsing effect
  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(`
    @keyframes pulse {
      0% {
        background: #e0e0e0;
      }
      50% {
        background: #c0c0c0;
      }
      100% {
        background: #e0e0e0;
      }
    }
  `, styleSheet.cssRules.length);

  return <div style={skeletonStyle} />;
};

export default Skeleton;
