import React, { useState, useEffect } from "react";
import ResizableCell from "components/Utility/ResizableCell";
import { toast, ToastContainer } from "react-toastify";
import { Spinner, Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import useAppStore from "store/global";
import { SortAlphaUp, SortAlphaDown } from "react-bootstrap-icons";
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  ZoomIn,
  ZoomOut,
  Download,
  SaveFill,
  XCircleFill,
  Trash3Fill
} from "react-bootstrap-icons";
import { getItemPdfs } from "../api";
import { useParams, Link } from "react-router-dom";
import { mergeItemMaster, deleteItemMaster } from "../api";
import CustomDropdown from "components/CustomDropdown";
import { itemMasterStore } from "store/itemMasterStore";

const PdfViewerComponent = ({
  vendorId,
  itemUUID,
  setViewInvoice,
  handleMultipleCellHover,
  hoveredCellCoordinates,
  getItemList,
  pdfs,
  setPdfs,
  currentPdfIndex,
  setCurrentPdfIndex
}) => {
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  useEffect(() => {
    setLoading(true);
    getItemPdfs(itemUUID, vendorId)
      .then((res) => {
        setPdfs(res.pdf_links);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [itemUUID, vendorId]);

  const handlePrevClick = () => {
    if (currentPdfIndex > 0) {
      setCurrentPdfIndex(currentPdfIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPdfIndex < pdfs.length - 1) {
      setCurrentPdfIndex(currentPdfIndex + 1);
    }
  };

  useEffect(() => {
    if (pdfs.length > 0) {
      const rowPolygons = [];
      const lineItem = pdfs[currentPdfIndex]?.line_item;
      // Iterate over the properties of line_item
      let pageIndex = 1;
      for (const key in lineItem) {
        //pageIndex = lineItem[0]?.page_index;
        if (Object.prototype.hasOwnProperty.call(lineItem, key)) {
          pageIndex = lineItem[key]?.page_index;
          const geometry = lineItem[key]?.geometry;
          if (geometry && geometry.Polygon) {
            rowPolygons.push(geometry.Polygon);
          }
        }
      }
      const pdfUrl = pdfs[currentPdfIndex]?.link;
      handleMultipleCellHover(rowPolygons, pageIndex + 1, pdfUrl);
    }
  }, [currentPdfIndex]);

  const currentPdf = pdfs.length > 0 ? pdfs[currentPdfIndex] : null;
  const currentPdfUrl = currentPdf ? currentPdf.link : null;
  const currentPdfSource = currentPdf ? currentPdf.source : null;


  return (
    <div className="container border border-warning rounded p-3 mb-4">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="row justify-content-center mt-3">
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-sm btn-primary w-100"
                onClick={handlePrevClick}
                disabled={currentPdfIndex === 0}
              >
                Previous
              </button>
            </div>
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-sm btn-primary w-100"
                onClick={handleNextClick}
                disabled={currentPdfIndex === pdfs.length - 1}
              >
                Next
              </button>
            </div>
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-sm btn-success w-100"
                disabled={
                  pdfs[currentPdfIndex] === null ||
                  pdfs[currentPdfIndex] === undefined ||
                  pdfs.length === 0
                }
                onClick={() =>
                  getItemList(pdfs[currentPdfIndex].document_uuid, true)
                }
              >
                Items
              </button>
            </div>
            <div className="col-md-2 mb-2">
              <Link
                className="btn btn-sm btn-info w-100"
                target="_blank"
                rel="noreferrer"
                to={`/invoice-details/${pdfs[currentPdfIndex]?.document_uuid}`}
              >
                Invoice
              </Link>
            </div>
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-sm btn-danger w-100"
                onClick={() => setViewInvoice(false)}
              >
                Close
              </button>
            </div>
          </div>
          <div className="">
            <div className="">
              {currentPdfSource === "google_drive" ? (
                <iframe
                  title="pdf"
                  src={`${currentPdfUrl?.replace(
                    "uc?id=",
                    "file/d/"
                  )}/preview?embedded=true`}
                  width="100%"
                  height="470"
                  allow="autoplay"
                ></iframe>
              ) : currentPdfSource === "azure_blob" ||
                currentPdfSource === "clickbacon" ? (
                <DocumentComponent
                  pdfUrl={currentPdfUrl}
                  hoveredCellCoordinates={hoveredCellCoordinates}
                />
              ) : (
                <div>No PDFs available</div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const DocumentComponent = ({ pdfUrl, hoveredCellCoordinates }) => {
  const [pdfScale, setPdfScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [numPages, setNumPages] = useState(null);
  //const [pageNum, setPageNum] = useState(1);
  const { pageNum, setPageNum } = useAppStore();
  function changePage(offset) {
    const newPageNum = pageNum + offset;
    setPageNum(newPageNum);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // setPageNum(1);
  }
  return (
    <>
      <div
        className="ml-50 p-2 rounded"
        style={{ zIndex: "50", backgroundColor: "rgb(240, 240, 240)" }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ gap: "16px" }}
        >
          <ZoomIn
            height={20}
            width={20}
            disabled={pdfScale >= 8}
            className="cursor-pointer"
            onClick={() => setPdfScale((s) => s * 2)}
          />
          <ZoomOut
            height={20}
            width={20}
            disabled={pdfScale <= 0.1}
            className="cursor-pointer"
            onClick={() => setPdfScale((s) => s / 2)}
          />
          <button
            type="button"
            disabled={pageNum <= 1}
            onClick={previousPage}
            className="btn text-center btn-sm btn-outline-secondary"
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNum >= numPages}
            onClick={nextPage}
            className="btn btn-sm btn-outline-secondary"
          >
            Next
          </button>
          <ArrowClockwise
            height={20}
            width={20}
            className="cursor-pointer"
            onClick={() => setRotation((r) => (r === 270 ? 0 : r + 90))}
          />
          <ArrowCounterclockwise
            height={20}
            width={20}
            className="cursor-pointer"
            onClick={() => setRotation((r) => (r === 0 ? 270 : r - 90))}
          />
          <div>
            <span>
              Page {pageNum} of {numPages}
            </span>
            <Download
              height={20}
              width={20}
              className="cursor-pointer mx-4"
              onClick={() => window.open(pdfUrl, "_blank")}
            />
          </div>
        </div>
      </div>
      <div style={{ height: "470px", overflow: "auto" }}>
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNum} scale={pdfScale} rotate={rotation}>
            {hoveredCellCoordinates && hoveredCellCoordinates.length > 0 && (
              <>
                {hoveredCellCoordinates.map((box, index) => (
                  <div
                    key={index}
                    id="bound-box"
                    style={{
                      position: "absolute",
                      left:
                        Math.min(
                          ...box.coordinates.map((coordinate) => coordinate.x)
                        ) + "px",
                      top:
                        Math.min(
                          ...box.coordinates.map((coordinate) => coordinate.y)
                        ) + "px",
                      width:
                        Math.max(
                          ...box.coordinates.map((coordinate) => coordinate.x)
                        ) -
                        Math.min(
                          ...box.coordinates.map((coordinate) => coordinate.x)
                        ) +
                        "px",
                      height:
                        Math.max(
                          ...box.coordinates.map((coordinate) => coordinate.y)
                        ) -
                        Math.min(
                          ...box.coordinates.map((coordinate) => coordinate.y)
                        ) +
                        "px",
                      // border: "1px solid rgba(144,238,144)",
                      padding: "5px",
                      background:
                        hoveredCellCoordinates.length <= 100
                          ? "rgba(144,238,144,0.17)"
                          : "rgba(144,238,144,0.02)",
                      borderRadius: "4px",
                      transform: "scale(1.1)",
                      pointerEvents: "none"
                    }}
                  />
                ))}
              </>
            )}
          </Page>
        </Document>
      </div>
    </>
  );
};

export default function VendorItemMastertable({
  items,
  approveLoading,
  handleCategoryChange,
  categoryChoices,
  handleMultipleCellHover,
  hoveredCellCoordinates,
  getItemList,
  sortItemDescAsc,
  setSortItemDescsAsc,
  sortItemCodeAsc,
  setSortItemCodeAsc,
  requiredItemColumns
}) {
  const [editableCell, setEditableCell] = useState(null);
  const [approveId, setApproveId] = useState(null);
  const [viewInvoice, setViewInvoice] = useState(false);
  const [selectedUUID, setSelectedUUID] = useState(null);
  const [rowToEdit, setRowToEdit] = useState(null);
  const { vendorId } = useParams();

  const {
    itemsToMerge,
    setItemsToMerge,
    masterBranchId,
    setMasterBranchId,
    masterUUID,
    setMasterUUID
  } = itemMasterStore();
  const [merging, setMerging] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [pdfs, setPdfs] = useState([]);
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
  useEffect(() => {
    console.log("checking rowToedit changes", rowToEdit);
  }, [rowToEdit]);
  if (!items || items.length === 0) {
    return <p className="text-center">No items available</p>;
  }
  const oheaders = Object.keys(items[0]).filter(
    (key) =>
      key !== "_id" &&
      key !== "item_uuid" &&
      key !== "branch_id" &&
      key !== "created_date" &&
      key !== "unit_price" &&
      key !== "document_count" &&
      key !== "vendor_address"
  );
  const desiredOrder = [...requiredItemColumns, "verified_by"];

  //Filter the headers based on the desired order
  const headers = desiredOrder.filter((header) => oheaders.includes(header));
  const handleEditClick = (e, id, row) => {
    setEditableCell(id);
    setRowToEdit(row);
  };

  const handleEditableCellBlur = () => {
    handleCategoryChange(rowToEdit);
    setEditableCell(null);
    setRowToEdit(null);
  };

  const handleApprove = (row) => {
    if (row.category === null) {
      toast.error("Please select a category");
      return;
    }
    handleCategoryChange(row, null);
    getItemList(null, true);
    setViewInvoice(false);
  };

  const handleItemMasterMerge = () => {
    if (masterUUID === null) {
      toast.error("Please select a master item");
      return;
    }
    if (itemsToMerge.length === 0) {
      toast.error("Please select items to merge");
      return;
    }
    setMerging(true);
    mergeItemMaster(vendorId, masterBranchId, masterUUID, itemsToMerge)
      .then((res) => {
        toast.success(res.message);
        setMerging(false);
        setMasterUUID(null);
        setMasterBranchId(null);
        setItemsToMerge([]);
        getItemList(null);
      })
      .catch((err) => {
        toast.error(err.message);
        setMerging(false);
      });
  };

  const handleDeleteItemMaster = (itemUUID, branchId, softDelete) => {
    setViewInvoice(false);
    deleteItemMaster(vendorId, branchId, itemUUID, softDelete)
      .then((res) => {
        toast.success(res.message);
        // getItemList(pdfs[currentPdfIndex]?.document_uuid, true);
        getItemList(null, true);
        setViewInvoice(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  return (
    <>
      <div style={{ overflowX: "auto", display: "flex" }}>
        <div
          className={`table-responsive  ${
            viewInvoice ? "col-md-6 mx-2" : "col-md-12"
          }`}
        >
          <table className="table table-striped table-hover table-bordered shadow-sm table-responsive">
            <thead>
              <tr>
                {headers.map((header) => (
                  <th
                    key={header}
                    style={{
                      textAlign: "center",
                      textTransform: "capitalize"
                    }}
                  >
                    <ResizableCell>
                      {header.replace(/_/g, " ")}
                      {header === "item_description" ? (
                        !sortItemDescAsc ? (
                          <>
                            <SortAlphaUp
                              className="mx-2"
                              height="20px"
                              width="20px"
                              fill="red"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSortItemDescsAsc("asc");
                              }}
                            />
                            <XCircleFill
                              className="text-danger mx-2"
                              onClick={() => setSortItemDescsAsc(null)}
                            />
                          </>
                        ) : (
                          <>
                            <SortAlphaDown
                              className="mx-2"
                              height="20px"
                              width="20px"
                              fill="blue"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSortItemDescsAsc("desc");
                              }}
                            />
                            <XCircleFill
                              className="text-danger mx-2"
                              onClick={() => setSortItemDescsAsc(null)}
                            />
                          </>
                        )
                      ) : header === "item_code" ? (
                        !sortItemCodeAsc ? (
                          <>
                            <SortAlphaUp
                              className="mx-2"
                              height="20px"
                              width="20px"
                              fill="red"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSortItemCodeAsc("asc");
                              }}
                            />
                            <XCircleFill
                              className="text-danger mx-2"
                              onClick={() => setSortItemDescsAsc(null)}
                            />
                          </>
                        ) : (
                          <>
                            <SortAlphaDown
                              className="mx-2"
                              height="20px"
                              width="20px"
                              fill="blue"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSortItemCodeAsc("desc");
                              }}
                            />
                            <XCircleFill
                              className="text-danger mx-2"
                              onClick={() => setSortItemDescsAsc(null)}
                            />
                          </>
                        )
                      ) : null}
                    </ResizableCell>
                  </th>
                ))}
                {editableCell == null && (
                  <th style={{ textAlign: "center" }}>Approve</th>
                )}
                {editableCell !== null && <th>Actions</th>}
                {editableCell == null && (
                  <>
                    <th style={{ textAlign: "center" }}>Select Master</th>
                    <th style={{ textAlign: "center" }}>Select Item</th>
                  </>
                )}
                <th>Invoice</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {items.map((row) => (
                <React.Fragment key={row.item_uuid}>
                  <tr>
                    {headers.map((header) => (
                      <td
                        style={{
                          textAlign: "center",
                          textTransform:
                            header === "verified_by" ? "none" : "capitalize"
                        }}
                        key={header}
                        onClick={(e) => {
                          if (row.item_uuid === editableCell) return;
                          handleEditClick(e, row.item_uuid, row);
                        }}
                      >
                        {editableCell === row.item_uuid &&
                        header == "category" ? (
                          <>
                            <CustomDropdown
                              data={categoryChoices?.sort()}
                              Objectkey={""}
                              displayKey={""}
                              className="w-auto"
                              maxWidth="250px"
                              minWidth="250px"
                              value={
                                rowToEdit["category"] || "Select a category"
                              }
                              showVerificationBadge={false}
                              verification_key={"human_verified"}
                              onSelect={(value) => {
                                setRowToEdit({
                                  ...rowToEdit,
                                  category: value
                                });
                              }}
                            />
                            {/* <select
                              className="form-control form-select mx-auto"
                              style={{ width: "80%" }}
                              value={rowToEdit["category"]}
                              onChange={(e) => {
                                setRowToEdit({
                                  ...rowToEdit,
                                  category: e.target.value,
                                });
                              }}
                            >
                              <option value={row[header]}>{row[header]}</option>
                              {categoryChoices.map((cat) => (
                                <option key={cat} value={cat}>
                                  {cat}
                                </option>
                              ))}
                            </select> */}
                          </>
                        ) : editableCell === row.item_uuid ? (
                          <textarea
                            rows={header == "item_description" ? "3" : "1"}
                            cols={header == "item_description" ? "60" : "10"}
                            type="text"
                            className="form-control"
                            defaultValue={row[header]}
                            onBlur={(e) => {
                              setRowToEdit({
                                ...rowToEdit,
                                [header]: e.target.value
                              });
                            }}
                          />
                        ) : typeof row[header] === "boolean" ? (
                          row[header] ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          row[header]
                        )}
                      </td>
                    ))}
                    {editableCell == null && (
                      <td
                        style={{
                          textAlign: "center"
                        }}
                      >
                        {approveLoading && approveId == row.item_uuid ? (
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="primary"
                          />
                        ) : row["human_verified"] === true ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="green"
                            className="bi bi-check-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="blue"
                            className="bi bi-hand-thumbs-up-fill"
                            viewBox="0 0 16 16"
                            onClick={() => {
                              setApproveId(row.item_uuid);
                              handleApprove(row);
                            }}
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg>
                        )}
                      </td>
                    )}
                    {editableCell !== null && (
                      <td>
                        {editableCell === row.item_uuid ? (
                          <>
                            <SaveFill
                              className="text-primary mx-1"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                handleEditableCellBlur();
                              }}
                            />
                            <XCircleFill
                              className="text-danger mx-1"
                              onClick={() => setEditableCell(null)}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    )}
                    <td>
                      <input
                        type="checkbox"
                        checked={masterUUID === row.item_uuid}
                        onChange={() => {
                          if (masterUUID === row.item_uuid) {
                            setMasterUUID(null);
                            setMasterBranchId(null);
                          } else {
                            setMasterUUID(row.item_uuid);
                            setMasterBranchId(row.branch_id);
                          }
                        }}
                        disabled={
                          itemsToMerge.find((item) => item === row.item_uuid)
                            ? true
                            : false
                        }
                      />
                    </td>
                    {editableCell == null && (
                      <>
                        <td>
                          <input
                            type="checkbox"
                            checked={itemsToMerge?.includes(row?.item_uuid)}
                            onChange={() => {
                              if (
                                itemsToMerge.find(
                                  (item) => item === row.item_uuid
                                )
                              ) {
                                setItemsToMerge(
                                  itemsToMerge.filter(
                                    (item) => item !== row.item_uuid
                                  )
                                );
                              } else {
                                setItemsToMerge([
                                  ...itemsToMerge,
                                  row.item_uuid
                                ]);
                              }
                            }}
                            disabled={
                              (masterBranchId !== null &&
                                masterBranchId !== row.branch_id)|| masterUUID === row?.branch_id ||
                              masterUUID === row.item_uuid
                            }
                          />
                        </td>
                        <td>
                          {viewInvoice && selectedUUID === row.item_uuid ? (
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                setViewInvoice(false);
                                setSelectedUUID(null);
                              }}
                            >
                              Hide
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                setViewInvoice(true);
                                setSelectedUUID(row.item_uuid);
                              }}
                            >
                              View
                            </button>
                          )}
                        </td>
                      </>
                    )}
                    <td>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          setItemToDelete(row);
                          setShowDeleteModal(true);
                        }}
                      >
                        <Trash3Fill />
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        {viewInvoice && (
          <PdfViewerComponent
            setViewInvoice={setViewInvoice}
            vendorId={vendorId}
            itemUUID={selectedUUID}
            handleMultipleCellHover={handleMultipleCellHover}
            hoveredCellCoordinates={hoveredCellCoordinates}
            getItemList={getItemList}
            pdfs={pdfs}
            setPdfs={setPdfs}
            currentPdfIndex={currentPdfIndex}
            setCurrentPdfIndex={setCurrentPdfIndex}
          />
        )}
        <ToastContainer />
      </div>
      <div className="">
        <button
          className="btn btn-primary my-2"
          onClick={handleItemMasterMerge}
          disabled={merging}
          style={{
            cursor: merging ? "not-allowed" : "pointer",
            width: "100%"
          }}
        >
          {merging ? (
            <Spinner size="sm" animation="border" variant="light" />
          ) : (
            "Merge Item Master"
          )}
        </button>
      </div>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Item Master</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete {itemToDelete?.item_description}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              handleDeleteItemMaster(
                itemToDelete.item_uuid,
                itemToDelete.branch_id,
                true
              );
              setShowDeleteModal(false);
            }}
          >
            Soft Delete
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              handleDeleteItemMaster(
                itemToDelete.item_uuid,
                itemToDelete.branch_id,
                false
              );
              setShowDeleteModal(false);
            }}
            disabled={true}
          >
            Hard Delete
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
