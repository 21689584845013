import Axios from "axios";
import { toast } from "react-toastify";
import { API_URL, NEW_BACKEND_URL } from "../config/env";
console.log(NEW_BACKEND_URL,"new")
const paramsSerializer = (params) => {
  let result = "";
  Object.keys(params).forEach((key) => {
    result += `${key}=${encodeURIComponent(params[key])}&`;
  });
  return result.substring(0, result.length - 1);
};

const authRequestInterceptor = (config) => {
  const authToken = localStorage.getItem("token");
  config.headers = config.headers ?? {};

  if (authToken) {
    config.headers.authorization = `Bearer ${authToken}`;
  }
  config.headers.Accept = "application/json";

  return config;
};

// export const responseInterceptor = (response) => {
//   return response.data;
// };

export const errorInterceptor = (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    window.location.href = "/login";
    return;
  }
  if (!error.response) {
    error.code !== "ERR_CANCELED" &&
      toast.error("Something went wrong with server");
    return Promise.reject(error);
  }

  const message =
    error?.response?.data?.message || error?.message || "Something went wrong";
  toast.error(message);
  return Promise.reject(error);
};

export const axiosInstance = Axios.create({
  baseURL: API_URL
});

export const instance = Axios.create({
  baseURL: NEW_BACKEND_URL
});

instance.interceptors.response.use(null, errorInterceptor);
axiosInstance.defaults.paramsSerializer = paramsSerializer;

axiosInstance.interceptors.request.use(authRequestInterceptor);

axiosInstance.interceptors.response.use(null, errorInterceptor);
// axios.interceptors.request.use((config) => {
//   config.data = { request: config.data };
//   return config;
// });
// axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
