import { Spinner } from "react-bootstrap";
import { useInvoiceTable } from "../api";
import useInvoicePaginationActions from "store/invoicePagination";
import { Status } from "assets/status";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import useStatisticsStore from "../store";
import { Pagination } from "components/common/Pagination";
import { formatDate } from "utils/format";
import useGlobalFilterStore from "store/globalFIlter";
import { useVendorList } from "components/layout/VendorDropdown";
import { useGlobalFilterActions } from "store/globalFIlter";
import ColorIndications from "assets/colorIndications";
import { PatchCheckFill } from "react-bootstrap-icons";
import Skeleton from "components/common/Skeleton";

const TH = ({ children }) => (
  <th className="text-center p-2" style={{ backgroundColor: "#FFF2CD" }}>
    {children}
  </th>
);

const stopPropagation = (event) => {
  event.preventDefault();
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
};

export const StatisticsTable = () => {
  const { dataV, isPendingV, errorV, isErrorV } = useVendorList();
  const { setVendorName } = useGlobalFilterActions();
  const navigate = useNavigate();
  const activeTab = useStatisticsStore((state) => state.activeTab);
  const allPagination = useStatisticsStore((state) => state.allPagination);
  const vendorPagination = useStatisticsStore(
    (state) => state.vendorPagination
  );
  const tableFilter = useStatisticsStore((state) => state.tableFilter);
  const vendorName = useGlobalFilterStore((state) => state.vendorName);
  const {
    verification,
    invoiceType,
    dateRange,
    invoiceDetectionStatus,
    verified,
    invoiceRerunStatus
  } = useGlobalFilterStore();
  const { data, isPending, isError, error, isLoading } = useInvoiceTable(
    allPagination.pageNumber,
    tableFilter.id,
    invoiceType,
    dateRange,
    vendorName,
    verification,
    invoiceDetectionStatus,
    verified,
    invoiceRerunStatus
  );
  const { setTempValue, setPageNumber } = useInvoicePaginationActions();
  const [reasonsVisibility, setReasonsVisibility] = useState(
    Array(11).fill(false)
  );
  const handleReasonsToggle = (e, i) => {
    stopPropagation(e);
    setReasonsVisibility((l) => l.map((e, j) => (j === i ? !e : e)));
  };
  useEffect(() => {
    if (activeTab === "vendor" && dataV) setVendorName(dataV[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div
      className="mx-5"
      style={{
        marginTop: isPending ? "0px" : "35px"
      }}
    >
      {isPending ? (
        // <Spinner />
        <></>
      ) : (
        isError && "En Error Occurred: " + error?.message
      )}
      <>
        <div className="d-flex justify-content-between align-items-center">
          <ColorIndications />
        </div>
        <Table
          striped
          bordered
          hover
          variant="light"
          responsive
          className="shadow-sm"
        >
          <thead>
            <tr>
              <TH>InvoiceID</TH>
              <TH>Source/Channel</TH>
              <TH>Restaurant</TH>
              <TH>Vendor</TH>
              <TH>Load Date</TH>
              <TH>Auto Balance Status</TH>
              <TH>Manual Balance Status</TH>
              <TH>Final Status</TH>
              <TH>Failure Cause Code</TH>
              <TH>Accepted/Rejected</TH>
              <TH>Rejected Reasons</TH>
              <TH>Reasons</TH>
              <TH>Invoice Type</TH>
              <TH>Human Verification Date</TH>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? [0, 1, 2, 3, 4].map((_, index) => {
                  return (
                    <tr key={index}>
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                      <td style={{height:'9vh',alignItems:'center',justifyContent:"center",textAlign:"center",paddingTop:30}} >
                        <Skeleton />
                      </td>
                
                    </tr>
                  );
                })
              : data?.tableData?.invoices_data?.map((item, i) => (
                  <>
                    <tr
                      onClick={() => {
                        const p = item?.invoice_index ?? 0;
                        setTempValue(p);
                        setPageNumber(p);
                        navigate("/details");
                      }}
                      key={`${item.invoice_index}${i}`}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                    >
                      <td>{item.invoice_id}</td>
                      <td>{item.channel}</td>
                      <td>{item.restaurant_id}</td>
                      <td>
                        {item.vendor}
                        {item.verified_vendor && (
                          <PatchCheckFill
                            style={{ color: "blue", marginLeft: "5px" }}
                          />
                        )}
                      </td>
                      <td>{formatDate(item.load_date)}</td>
                      <td>
                        <Status>{item.auto_balance_status}</Status>
                      </td>
                      <td>
                        <Status>{item.manual_balance_status}</Status>
                      </td>
                      <td>
                        <Status>{item.final_status}</Status>
                      </td>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill={
                            item.failure_cause_code === 0 ||
                            item.failure_cause_code === 1 ||
                            item.failure_cause_code === 2 ||
                            item.failure_cause_code === 3 ||
                            item.failure_cause_code === 4
                              ? "red"
                              : item.failure_cause_code === 5
                              ? "yellow"
                              : item.failure_cause_code === 6
                              ? "orange"
                              : item.failure_cause_code === -1
                              ? "green"
                              : "black"
                          }
                        >
                          <circle
                            fill={
                              item.failure_cause_code === 0 ||
                              item.failure_cause_code === 1 ||
                              item.failure_cause_code === 2 ||
                              item.failure_cause_code === 3 ||
                              item.failure_cause_code === 4
                                ? "red"
                                : item.failure_cause_code === 5
                                ? "yellow"
                                : item.failure_cause_code === 6
                                ? "orange"
                                : item.failure_cause_code === -1
                                ? "green"
                                : "black"
                            }
                            cx="8"
                            cy="8"
                            r="8"
                          />
                        </svg>
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {item?.accepted_or_rejected}
                      </td>
                      <td>{item?.rejected_reason}</td>
                      <td className="text-center">
                        <button
                          className="btn btn-warning"
                          disabled={item.reasons?.length === 0}
                          onClick={(e) => handleReasonsToggle(e, i)}
                          // data-toggle="collapse"
                          // data-target={`${item.invoice_index}reasons`}
                        >
                          View
                        </button>
                      </td>
                      <td>{item.invoice_type}</td>
                      <td>{formatDate(item.human_verification_date)}</td>
                    </tr>
                    {reasonsVisibility[i] &&
                      item.reasons.map((r, j) => (
                        <tr key={j}>
                          <td align="center" colspan="11">
                            {r}
                          </td>
                        </tr>
                      ))}
                  </>
                ))}
          </tbody>
        </Table>
        <Pagination
          totalInvoices={data?.totalPages ?? 1}
          {...(activeTab === "all" ? allPagination : allPagination)}
        />
      </>
    </div>
  );
};
