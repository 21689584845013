import React, { useState, useEffect } from "react";
import {
  Form,
  Dropdown,
  Spinner,
  FormControl,
  Modal,
  Button,
} from "react-bootstrap";
import useAppStore from "store/global";
import { invoiceFilterOptions } from "store/globalFIlter";
import { useQuery, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "lib/axios";
import { toast } from "react-toastify";
import { PatchCheckFill, PenFill, Save2Fill } from "react-bootstrap-icons";
import DatePicker from "react-date-picker";
import { format, isValid, parse } from "date-fns";
import CustomDropdown from "components/CustomDropdown";
import { queryClient } from "lib/react-query";
import useGetNestedFilterValue from "hooks/useGetNestedFilterValue";
function formatBootstrapDate(inputDate) {
  try {
    if (!inputDate || inputDate == null || inputDate == "" || inputDate == "NA")
      return "";
    const odate = new Date(inputDate);
    const date = new Date(odate);
    date.setDate(odate.getDate() + 1);

    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  } catch (error) {
    return "";
  }
}

export const useVendorList = () =>
  useQuery({
    queryKey: ["getVendors", "allVendorNames"],
    queryFn: async () => {
      const res = await axiosInstance.get(`/get_vendor_names`);
      return res.data.vendor_names;
    },
  });

export const useVendorBranchData = (vendorId) =>
  useMutation({
    mutationKey: ["getVendorBranchData"],
    mutationFn: async () => {
      const res = await axiosInstance.post(
        "/vendor_page/get_all_vendor_branch_data",
        {
          vendor_id: vendorId,
          page_index: null,
          page_size: null,
          address_search: null,
        }
      );
      return res.data.vendors_branch_data;
    },
  });

export const useRenameVendor = () =>
  useMutation({
    mutationKey: ["renameVendor"],
    mutationFn: async (body) => {
      const res = await axiosInstance.post(
        "/rename_vendor_branch_address",
        // convert body to json
        body
      );
      return res.data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(error.response.data.message);
    },
  });

export const useUpdateInvoiceBranch = () =>
  useMutation({
    mutationKey: ["updateBranch"],
    mutationFn: async (body) => {
      const res = await axiosInstance.post(
        "/update_invoice_branch",
        // convert body to json
        body
      );
      return res.data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries("getVendors");
      queryClient.invalidateQueries("getVendorBranchData");
    },
    onError: (error) => {
      toast.error(error.response.data.message);
    },
  });

const TR = ({ value, label, onChange, ...props }) => (
  <tr>
    <th
      style={{
        width: "200px",
        backgroundColor: "#FFF2CD",
        textTransform: "capitalize",
      }}
    >
      {label}
    </th>
    <td>
      <Form.Control
        style={{ minWidth: "100px" }}
        value={value ?? ""}
        onChange={(e) => onChange(e.target.value)}
        width={"100%"}
        {...props}
      />
    </td>
  </tr>
);

export default function EditMetaDataTable({ getExtractedInvoice }) {
  const {
    invoiceType,
    setInvoiceType,
    invoiceNum,
    setInvoiceNum,
    dueDate,
    setdueDate,
    invoiceDate,
    setInvoiceDate,
    vendorName,
    setvendorName,
    invoiceShipTo,
    setInvoiceShipTo,
    invoiceBillTo,
    setInvoiceBillTo,
    invoiceSoldTo,
    setInvoiceSoldTo,
    invoiceUUID,
    vendorAddress,
    setVendorAddress,
    quickBooksDocType,
    setQuickBooksDocType,
    vendorId,
    setVendorId,
    branchId,
    setBranchId,
    documentTypePrediction,
    setDocumentTypePrediction,
    creditCardName,
    setCreditCardName,
    creditCardNumber,
    setCreditCardNumber,
  } = useAppStore();
  const getNestedFilterValue = useGetNestedFilterValue();
  const rest_id = getNestedFilterValue(["filters", "restaurant_id"])
  const { data, isPending, error, isError } = useVendorList();
  const {
    mutate,
    data: vendorBranchData,
    isLoading,
    isError: isErrorInVendorDetails,
    error: errorInVendorDetails,
  } = useVendorBranchData(vendorId);
  useEffect(() => {
    mutate();
  }, [mutate, vendorId]);
  const {
    mutate: renameVendor,
    isLoading: isRenaming,
    data: renameData,
    isError: isRenameError,
    error: renameError,
  } = useRenameVendor();
  const {
    mutate: updateInvoiceBranch,
    isLoading: isUpdating,
    data: updateData,
    isError: isUpdateError,
    error: updateError,
  } = useUpdateInvoiceBranch();
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddressUpdatePromptModal, setShowAddressUpdatePromptModal] =
    useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [showAddressDropdown, setShowAddressDropdown] = useState(true);
  const [showVendorChangeConfirmation, setShowVendorChangeConfirmation] =
    useState(false);
  const [showAddressChangeConfirmation, setShowAddressChangeConfirmation] =
    useState(false);
  const [newBranchId, setNewBranchId] = useState(null);
  const handleClose = () => setShowVendorChangeConfirmation(false);
  const handleAddressChangeConfirmationClose = () =>
    setShowAddressChangeConfirmation(false);
  const [filteredVendors, setFilteredVendors] = useState([]);

  useEffect(() => {
    if (!data) return;
    const lowercaseSearchQuery = searchQuery.toLowerCase();
    const filtered = data.filter((vendor) =>
      vendor.vendor_name
        .toLowerCase()
        .trim()
        .includes(lowercaseSearchQuery.trim())
    );
    setFilteredVendors((prev) => filtered);
  }, [searchQuery, data]);

  const [newVendorName, setNewVendorName] = useState("");
  const [loading, setLoading] = useState(false);

  console.log("the date is", dueDate);

  const changeVendorName = async () => {
    setLoading(true);
    const body = {
      invoice_uuid: invoiceUUID,
      current_vendor_name: vendorName,
      updated_vendor_name: newVendorName,
      vendor_address: vendorAddress,
    };
    const response = await axiosInstance.post("/update_vendor_name", body);
    if (response.status === 200) {
      toast.success(response.data.message);
      setvendorName(newVendorName);
      setShowVendorChangeConfirmation(false);
      setNewBranchId(branchId);
      setShowAddressUpdatePromptModal(true);
      setLoading(false);
    } else {
      toast.error(response.data.message);
      setLoading(false);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  console.log(vendorBranchData);
  return (
    <div
      style={{
        width: "100%",
        height: "471px",
        overflowX: "auto",
        overflowY: "auto",
      }}
    >
      <table className="table table-bordered">
        <tbody>
          <TR
            label={"Invoice Number"}
            value={invoiceNum}
            onChange={setInvoiceNum}
          />

          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Type
            </th>
            <td>
              <select
                style={{ minWidth: "100px" }}
                className="form-select w-100"
                onChange={(e) => setInvoiceType(e.target.value)}
                value={invoiceType}
              >
                {invoiceFilterOptions.slice(1).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </td>
          </tr>

          {/* <TR
            label={"Vendor Names"}
            value={vendorName}
            onChange={setvendorName}
          /> */}

          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Change Vendor Name
            </th>
            <td className="d-flex justify-content-between align-items-center">
              <Dropdown
                autoClose={"outside"}
                onSelect={(v) => {
                  if (v === "-1") {
                    return;
                  }
                  setNewVendorName(v);
                }}
                style={{ width: "90%", maxWidth: "300px" }}
              >
                <Dropdown.Toggle
                  variant="outline"
                  className="border w-100 bg-white h-100"
                  style={{
                    lineHeight: "2.0",
                    fontSize: "14px",
                  }}
                >
                  {newVendorName !== ""
                    ? newVendorName
                    : vendorName?.length > 0 && vendorName !== "NA"
                    ? vendorName
                    : "Select a Vendor"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    maxWidth: "300px",
                    minHeight: "100px",
                    maxHeight: "300px",
                    overflowY: "scroll",
                    overflowX: "auto",
                  }}
                >
                  {isPending ? (
                    <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                      <Spinner />
                    </div>
                  ) : isError ? (
                    error.message
                  ) : (
                    <>
                      <FormControl
                        type="text"
                        className="form-control mx-auto mb-4"
                        placeholder="Search"
                        onInput={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                        style={{
                          width: "90%",
                        }}
                      />
                      {filteredVendors?.map((vendor, i) => (
                        <Dropdown.Item
                          className="d-flex justify-content-between align-items-center"
                          key={vendor.vendor_id}
                          eventKey={vendor.vendor_name}
                        >
                          {vendor.vendor_name}
                          {vendor.human_verified && (
                            <PatchCheckFill fill="blue" />
                          )}
                        </Dropdown.Item>
                      ))}
                      <Dropdown.Item eventKey={null}>{"NA"}</Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <button
                className=" mx-1 btn btn-warning"
                style={{ width: "30%" }}
                onClick={() => setShowVendorChangeConfirmation(true)}
                disabled={loading || newVendorName === ""}
              >
                {loading && (
                  <Spinner className="mx-2" animation="border" size="sm" />
                )}
                {loading ? "Confirming" : "Confirm"}
              </button>
            </td>
          </tr>

          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Vendor Address
            </th>
            <td>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className={`d-flex ${
                  !showAddressDropdown ? "justify-content-between w-100" : ""
                } `}
              >
                {showAddressDropdown ? (
                  // <Dropdown
                  //   className="col-md-10"
                  //   autoClose={"outside"}
                  //   onSelect={(v) => {
                  //     const { branch } = JSON.parse(v);
                  //     setVendorAddress(branch.vendor_address);
                  //     setBranchId(branch.branch_id);
                  //   }}
                  //   style={{ width: "90%" }}
                  // >
                  //   <Dropdown.Toggle
                  //     variant="outline"
                  //     className="border bg-white h-100"
                  //     style={{
                  //       lineHeight: "2.0",
                  //       fontSize: "14px",
                  //       whiteSpace: "nowrap",
                  //       overflow: "hidden",
                  //       textOverflow: "ellipsis",
                  //       width: "90%",
                  //       maxWidth: "300px",
                  //     }}
                  //   >
                  //     {vendorAddress ?? "Select a Vendor Address"}
                  //   </Dropdown.Toggle>
                  //   {isErrorInVendorDetails ? (
                  //     <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                  //       {errorInVendorDetails?.message}
                  //     </div>
                  //   ) : isLoading ? (
                  //     <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                  //       <Spinner />
                  //     </div>
                  //   ) : (
                  //     <Dropdown.Menu
                  //       style={{
                  //         maxWidth: "300px",
                  //         minHeight: "100px",
                  //         maxHeight: "300px",
                  //         overflowY: "auto",
                  //         overflowX: "auto",
                  //       }}
                  //     >
                  //       {vendorBranchData?.map((branch, i) => (
                  //         <Dropdown.Item
                  //           className="d-flex justify-content-between align-items-center"
                  //           key={branch.vendor_address}
                  //           eventKey={JSON.stringify({ branch })}
                  //         >
                  //           {branch.vendor_address}{" "}
                  //           {branch.human_verified && (
                  //             <PatchCheckFill fill="blue" />
                  //           )}
                  //         </Dropdown.Item>
                  //       ))}
                  //     </Dropdown.Menu>
                  //   )}
                  // </Dropdown>
                  <CustomDropdown
                    data={vendorBranchData}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    maxWidth=""
                    minWidth="400px"
                    showVerificationBadge={true}
                    value={vendorAddress ?? "Select a Vendor Address"}
                    // setValue={setValue}
                    Objectkey={"branch_id"}
                    displayKey={"vendor_address"}
                    alternateObjectKey={"vendor_address"}
                    onSelect={(vid, va) => {
                      console.log("here222", va, vid);
                      setVendorAddress(va);
                      setBranchId(vid);
                    }}
                  />
                ) : (
                  <Form.Control
                    as={"textarea"}
                    className="mx-1"
                    value={vendorAddress ?? ""}
                    onChange={(e) => setVendorAddress(e.target.value)}
                    style={{ width: "80%" }}
                  />
                )}
                {showAddressDropdown ? (
                  <button
                    className="btn btn-sm btn-warning mx-2"
                    style={{ width: "10%" }}
                    onClick={() => {
                      setShowAddressDropdown(!showAddressDropdown);
                    }}
                  >
                    {isRenaming ? (
                      <Spinner className="mx-2" animation="border" size="sm" />
                    ) : (
                      <PenFill />
                    )}
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-warning mx-2"
                    disabled={vendorAddress === "" || vendorAddress === null}
                    style={{ width: "10%" }}
                    onClick={() => {
                      setShowAddressDropdown(!showAddressDropdown);
                    }}
                  >
                    {isRenaming ? (
                      <Spinner className="mx-2" animation="border" size="sm" />
                    ) : (
                      <Save2Fill />
                    )}
                  </button>
                )}
              </div>

              <div className="my-1 mx-2">
                <button
                  className="btn btn-sm btn-warning w-100"
                  disabled={vendorAddress === "" || vendorAddress === null}
                  onClick={() => {
                    setShowAddressChangeConfirmation(true);
                  }}
                >
                  {isRenaming && (
                    <Spinner className="mx-2" animation="border" size="sm" />
                  )}
                  Update Address
                </button>
              </div>
            </td>
          </tr>

          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Quick Books Document Type
            </th>
            <td>
              <Dropdown
                autoClose={"outside"}
                onSelect={(v) => {
                  setQuickBooksDocType(v);
                }}
                width={"100%"}
              >
                <Dropdown.Toggle
                  variant="outline"
                  className="border w-100 bg-white h-100"
                  style={{
                    lineHeight: "2.0",
                    fontSize: "14px",
                  }}
                >
                  {quickBooksDocType !== null
                    ? quickBooksDocType
                    : "Select a Document Type"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  <>
                    <Dropdown.Item key="expense" eventKey="expense">
                      Expense
                    </Dropdown.Item>
                    <Dropdown.Item key="invoice" eventKey="invoice">
                      Invoice
                    </Dropdown.Item>
                    <Dropdown.Item key="bill" eventKey="bill">
                      Bill
                    </Dropdown.Item>
                    <Dropdown.Item key="credit_memo" eventKey="credit_memo">
                      Credit Memo
                    </Dropdown.Item>
                    <Dropdown.Item key="vendor_credit" eventKey="vendor_credit">
                      Vendor Credit
                    </Dropdown.Item>
                    <Dropdown.Item key="check" eventKey="check">
                      Check
                    </Dropdown.Item>
                  </>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Document Type Prediction
            </th>
            <td>
              <Dropdown
                autoClose={"outside"}
                onSelect={(v) => {
                  setDocumentTypePrediction(v);
                }}
                width={"100%"}
              >
                <Dropdown.Toggle
                  variant="outline"
                  className="border w-100 bg-white h-100"
                  style={{
                    lineHeight: "2.0",
                    fontSize: "14px",
                    textTransform: "capitalize",
                  }}
                >
                  {documentTypePrediction !== null
                    ? documentTypePrediction
                    : "Select a Document Type"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  <>
                    <Dropdown.Item key="invoice" eventKey="invoice">
                      Invoice
                    </Dropdown.Item>
                    <Dropdown.Item key="receipt" eventKey="receipt">
                      Receipt
                    </Dropdown.Item>
                  </>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          {/* <TR
            label={"Due Date"}
            type="date"
            value={dueDate}
            onChange={setdueDate}
            format={"mm-dd-yyyy"}
          /> */}
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Due Date
            </th>
            <td>
           <DatePicker
      showIcon
      format="MM-dd-yyyy"
      value={dueDate ? parse(dueDate, "MM-dd-yyyy", new Date()) : null} // Handle null case
      locale="en-US"
      onChange={(date) => {
        if (date && isValid(date)) {
          setdueDate(format(date, "MM-dd-yyyy"));
        } else {
          setdueDate(null); // Handle clearing the date
        }
      }}
    />
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Date
            </th>
            <td>
              <DatePicker
                format="MM-dd-yyyy"
                locale="en-US"
                value={
                  invoiceDate && parse(invoiceDate, "MM-dd-yyyy", new Date())
                }
                onChange={(date) => {
                  
                  // setInvoiceDate(format(date, "MM-dd-yyyy"))
                  if (date && isValid(date)) {
                    setInvoiceDate(format(date, "MM-dd-yyyy"));
                  } else {
                    setInvoiceDate(null); // Handle clearing the date
                  }
                }}
              />
            </td>
          </tr>

          {/* <TR
            label={"Invoice Date"}
            type="date"
            value={invoiceDate}
            onChange={setInvoiceDate}
            format={"mm-dd-yyyy"}
          /> */}

          {/* <TR
            label={"Vendor Address"}
            value={vendorAddress}
            onChange={setVendorAddress}
          /> */}

          <TR
            label={"Invoice Ship To"}
            value={invoiceShipTo}
            onChange={setInvoiceShipTo}
          />

          <TR
            label={"Invoice Bill To"}
            value={invoiceBillTo}
            onChange={setInvoiceBillTo}
          />

          <TR
            label={"Invoice Sold To"}
            value={invoiceSoldTo}
            onChange={setInvoiceSoldTo}
          />

          <TR
            label={"Credit Card Name"}
            type="text"
            value={creditCardName}
            onChange={setCreditCardName}
          />

          <TR
            label={"Credit Card Number"}
            type="text"
            value={creditCardNumber}
            onChange={setCreditCardNumber}
          />
        </tbody>
      </table>
      <Modal
        show={showVendorChangeConfirmation}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Vendor Change
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to change the vendor?</h5>
          <ul>
            <li>Old Vendor Name: {vendorName}</li>
            <li>New Vendor Name: {newVendorName}</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading || newVendorName === ""}
            variant="success"
            onClick={() => changeVendorName()}
          >
            {loading ? "Processing" : "Confirm"}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAddressChangeConfirmation}
        onHide={handleAddressChangeConfirmationClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Vendor Address Change
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to change the vendor address?</h5>
          <ul>
            <li>New Vendor Address: {vendorAddress}</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              isRenaming || vendorAddress === "" || vendorAddress === null
            }
            variant="success"
            onClick={() => {
              const body = {
                invoice_uuid: invoiceUUID,
                branch_id: branchId,
                vendor_id: vendorId,
                updated_vendor_address: vendorAddress,
              };
              try {
                renameVendor(body);
                setShowAddressDropdown(!showAddressDropdown);
                getExtractedInvoice();
              } catch (e) {
                setShowAddressDropdown(!showAddressDropdown);
                //console.log(e);
              }
            }}
          >
            {loading ? "Processing" : "Confirm"}
          </Button>
          <Button
            variant="danger"
            onClick={handleAddressChangeConfirmationClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        // show = {true}
        show={showAddressUpdatePromptModal}
        onHide={() => setShowAddressUpdatePromptModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Vendor Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Do you want to</h5>
          <div className="d-flex">
            <input
              className="form-check"
              type="radio"
              name="address"
              value={!createNew}
              onChange={(e) => setCreateNew(!e.target.value)}
            />
            <label className="mx-2">Create a New Branch</label>{" "}
          </div>
          <div className="d-flex">
            <input
              className="form-check"
              type="radio"
              name="address"
              value={createNew}
              onChange={(e) => setCreateNew(e.target.value)}
            />
            <label className="mx-2">Merge with other Brach</label>{" "}
          </div>
          <div>
            {createNew ? (
              <Dropdown
                autoClose={"outside"}
                onSelect={(v) => {
                  const { branch } = JSON.parse(v);
                  setNewBranchId(branch.branch_id);
                  // setVendorAddress(branch.vendor_address);
                  // setBranchId(branch.branch_id);
                }}
                style={{ width: "90%" }}
              >
                <Dropdown.Toggle
                  variant="outline"
                  className="border bg-white h-100"
                  style={{
                    lineHeight: "2.0",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "90%",
                    maxWidth: "300px",
                  }}
                >
                  {newBranchId === branchId
                    ? "Select a Branch"
                    : vendorBranchData?.find(
                        (branch) => branch.branch_id === newBranchId
                      )?.vendor_address ?? "Select a Branch"}
                </Dropdown.Toggle>
                {isErrorInVendorDetails ? (
                  <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                    {errorInVendorDetails?.message}
                  </div>
                ) : isLoading ? (
                  <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                    <Spinner />
                  </div>
                ) : (
                  <Dropdown.Menu
                    style={{
                      maxWidth: "300px",
                      minHeight: "100px",
                      maxHeight: "300px",
                      overflowY: "auto",
                      overflowX: "auto",
                    }}
                  >
                    {vendorBranchData?.map((branch, i) => (
                      <Dropdown.Item
                        className="d-flex justify-content-between align-items-center"
                        key={branch.vendor_address}
                        eventKey={JSON.stringify({ branch })}
                      >
                        {branch.vendor_address}{" "}
                        {branch.human_verified && (
                          <PatchCheckFill fill="blue" />
                        )}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                )}
              </Dropdown>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isUpdating}
            variant="success"
            onClick={() => {
              const body = {
                invoice_uuid: invoiceUUID,
                current_branch_id: createNew ? branchId : "null",
                master_branch_id: createNew ? newBranchId : "null",
                merge_with_master_branch: createNew ? true : false,
              };
              try {
                updateInvoiceBranch(body);
                setNewBranchId(null);
                setShowAddressUpdatePromptModal(false);
                getExtractedInvoice(false);
              } catch (e) {
                //console.log(e);
              }
            }}
          >
            {loading ? "Processing" : createNew ? "Merge" : "Create"}
          </Button>
          <Button
            variant="danger"
            onClick={() => setShowAddressUpdatePromptModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
