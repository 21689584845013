import { create } from "zustand";

export const itemMasterStore = create((set, get) => ({
  itemsToMerge: [],
  masterBranchId: null,
  masterUUID: null,
  setMasterBranchId: (branchId) => set({ masterBranchId: branchId }),
  setItemsToMerge: (items) => set({ itemsToMerge: items }),
  setMasterUUID: (UUID) => set({ masterUUID: UUID })
}));
